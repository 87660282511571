import { connect, FieldArray, Field as FormikField, ErrorMessage, useFormikContext, getIn } from 'formik'
import { formFieldNames } from './formFieldNames'
import { Button, Field } from '@/ui'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './TariffRatesFields.module.scss'
import { Add, Delete } from '@app/icons'
import cl from 'classnames'
import { useSelector } from 'react-redux'

let innerInitialState = {
	gradeCoeff: '',
	gradeLevel: 1,
	salarySupplement: '',
	tariffRate: '',
}

let outerInitialState = {
	gradeList: [innerInitialState],
	positionCode: '',
	workConditionsCode: '',
}

export const TariffRatesFields = connect(() => {
	const { t } = useTranslation()

	const { errors } = useFormikContext()

	const { positionsByKnz, workingCondition } = useSelector((state) => ({
		positionsByKnz: state.handbook.positionsByKnz,
		workingCondition: state.handbook.workCondition,
	}))

	const nameError = useMemo(() => {
		return typeof errors.positions === 'string' ? formFieldNames.positions : null
	}, [errors])

	return (
		<>
			<FieldArray name={formFieldNames.positions}>
				{({ form: { values: outerValues }, push: outerPush, remove: outerRemove, ...props }) => {
					return (
						<div className={styles.tariff_content}>
							{outerValues?.[formFieldNames.positions]?.map((position, positionIdx) => (
								<div key={positionIdx}>
									<div className={cl(styles.fields__wrapper, styles.fields_verticalCenter, 'mt-4')}>
										<span
											className={styles.tariff_content__subtitle}>{t('col_contract.info_by_work_place', { count: positionIdx + 1 })}</span>
										{
											positionIdx !== 0 &&
											<Button type="bare" onClick={() => outerRemove(positionIdx)}
															buttonClassName={cl(styles.buttonRed, 'mt-0')}>
												<Delete />
												<span className="ms-2">{t('remove')}</span>
											</Button>
										}
									</div>
									<div
										className={cl(styles.tariff_content__wrapper, styles.tariff_content__wrappertwoOne, 'mt-2')}>
										<FormikField
											name={`${formFieldNames.positions}.[${positionIdx}].${formFieldNames.positionCode}`}>
											{({ field, meta: { touched, error } }) => (
												<Field
													fieldType={'select'}
													isRequired
													label={t('personal_affairs.nkz_position')}
													placeholder={t('choose_from_directory')}
													options={positionsByKnz || []}
													error={touched && error}
													{...field}
												/>
											)}
										</FormikField>
										<FormikField
											name={`${formFieldNames.positions}.[${positionIdx}].${formFieldNames.workConditionsCode}`}>
											{({ field, meta: { touched, error } }) => (
												<Field
													fieldType={'select'}
													isRequired
													label={t('contract.info.working_condition')}
													placeholder={t('choose_from_directory')}
													options={workingCondition || []}
													error={touched && error}
													{...field}
												/>
											)}
										</FormikField>
									</div>

									<div className={cl('px-3', 'mt-4')}>
										<FieldArray
											name={`${formFieldNames.positions}.[${positionIdx}].${formFieldNames.gradeList}`}>
											{({ push: innerPush, remove: innerRemove }) => {
												return (
													<div>
														{
															outerValues?.[formFieldNames.positions][positionIdx][formFieldNames.gradeList]?.map((grade, gradeIdx) => (
																<div key={`${positionIdx}-${gradeIdx}`} className={cl('mt-2')}>
																	<div className={cl(styles.fields__wrapper, styles.fields_verticalCenter)}>
																		<span
																			className={styles.tariff_content__subtitle}>{t('col_contract.rank_by_count', { count: gradeIdx + 1 })}</span>
																			<Button type="bare" onClick={() => innerRemove(gradeIdx)}
																							buttonClassName={cl(styles.buttonRed, 'mt-0')}>
																				<Delete />
																				<span className="ms-2">{t('remove')}</span>
																			</Button>
																	</div>
																	<div
																		className={cl(styles.tariff_content__wrapper, styles.tariff_content__wrapperthree, 'mt-2')}>
																		<FormikField
																			name={`${formFieldNames.positions}.[${positionIdx}].${formFieldNames.gradeList}.[${gradeIdx}].${formFieldNames.tariffRate}`}>
																			{({ field, meta: { touched, error } }) => (
																				<Field
																					{...field}
																					isRequired
																					label={t('col_contract.tariff_rate_salary')}
																					placeholder={t('col_contract.set_count')}
																					error={touched && error}
																					wrapperClassName={styles.field__input}
																					maxLength={12}
																					onInput={(e) => {
																						e.target.value = e.target.value.replace(/[^0-9]/g, '')
																					}}
																				/>
																			)}
																		</FormikField>
																		<FormikField
																			name={`${formFieldNames.positions}.[${positionIdx}].${formFieldNames.gradeList}.[${gradeIdx}].${formFieldNames.salarySupplement}`}>
																			{({ field, meta: { touched, error } }) => (
																				<Field
																					{...field}
																					isRequired
																					label={t('staffing_table.supplement_surcharge')}
																					placeholder={t('col_contract.set_supplement_surcharge')}
																					error={touched && error}
																					wrapperClassName={styles.field__input}
																					maxLength={12}
																					onInput={(e) => {
																						e.target.value = e.target.value.replace(/[^0-9]/g, '')
																					}}
																				/>
																			)}
																		</FormikField>
																		<FormikField
																			name={`${formFieldNames.positions}.[${positionIdx}].${formFieldNames.gradeList}.[${gradeIdx}].${formFieldNames.gradeCoeff}`}>
																			{({ field, meta: { touched, error } }) => (
																				<Field
																					{...field}
																					isRequired
																					label={t('col_contract.inter_digit_coefficient')}
																					placeholder={t('col_contract.set_coefficient')}
																					error={touched && error}
																					wrapperClassName={styles.field__input}
																					maxLength={12}
																					type="number"
																					min={0}
																					hideNumberArrows
																					enablecheckfloat
																				/>
																			)}
																		</FormikField>
																	</div>
																</div>
															))
														}
														{
															outerValues?.[formFieldNames.positions][positionIdx][formFieldNames.gradeList]?.length < 8 &&
															<Button transparent onClick={() => innerPush({
																...innerInitialState,
																gradeLevel: outerValues?.[formFieldNames.positions][positionIdx][formFieldNames.gradeList]?.length + 1,
															})} buttonClassName={cl(styles.button, 'mt-0')}>
																<Add className={styles.iconGreen} />
																{t('col_contract.add_rank')}
															</Button>
														}

													</div>
												)
											}}
										</FieldArray>
									</div>
								</div>
							))}

							<Button transparent onClick={() => outerPush(outerInitialState)}
											buttonClassName={cl(styles.button, 'mt-0', 'w-100')}>
								<Add className={styles.iconGreen} />
								{t('col_contract.add_info_work_place')}
							</Button>
						</div>
					)
				}}
			</FieldArray>
			{nameError && <ErrorMessage name={nameError} render={(text) => <span className={styles.errorText}>{t('required_field')}</span>}/>}
		</>
	)
})
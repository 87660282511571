import * as types from './types'

export const actions = {
	setRegionalPower: (payload) => ({
		type: types.SET_REGIONAL_POWER_CONTACT,
		payload,
	}),

	setFeedbackAction: (payload) => ({
		type: types.SET_FEEDBACK_ACTION,
		payload,
	}),
	setFeedbackActionDone: (payload) => ({
		type: types.SET_FEEDBACK_ACTION_DONE,
		payload,
	}),
	setFeedbackActionFail: (payload) => ({
		type: types.SET_FEEDBACK_ACTION_FAIL,
		payload,
	}),
}

import cl from 'classnames'
import styles from './Style.module.scss'
import block1 from '@app/images/employer_ico_1.svg'
import block2 from '@app/images/employer_ico_2.svg'
import block3 from '@app/images/employer_ico_3.svg'
import block4 from '@app/images/employer_ico_4.svg'
import block5 from '@app/images/employer_ico_5.svg'
import { useLanguage, useWindowSize } from '@/shared'
import { useTranslation } from 'react-i18next'
import { StyledSlider } from '@features/home/Styled'
import React from 'react'

const settings = {
	infinite: true,
	swipeToSlide: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	initialSlide: 0,
}

const Employer = () => {
	const { t } = useTranslation()
	const { breakpoint } = useWindowSize()

	const array = [
		{
			icon: block1,
			title: t('register_employment_contracts_online'),
			body: t('search_qualified_workers'),
		},
		{
			icon: block2,
			title: t('form_store_personal'),
			body: t('manage_entire_package'),
		},
		{
			icon: block3,
			title: t('use_template_contracts'),
			body: t('types_employment_contracts_agreements'),
		},
		{
			icon: block4,
			title: t('access_single_employee_profile'),
			body: t('informed_experience_employment_employee'),
		},
		{
			icon: block5,
			title: t('work_verified_documents'),
			body: t('save_time_processing'),
		},
	]

	return (
		<section data-section="employer" className={cl(styles.employer, styles.grid)}>
			<div className={cl(styles.container)}>
				{
					breakpoint.md ?
						<div className={cl(styles.grid__grid, styles.grid__line)}>
							<div className={cl(styles.grid__block, styles.grid__block0)}>
								<h2 className={cl('mb-0', styles.grid__title)}>{t('for_employer')}</h2>
								<span className={cl(styles.grid__subtitle)}>{t('source_portal')}</span>
							</div>

							{
								array.map((item, index) => (
									<div key={index}
											 className={cl(styles.grid__block, styles[`grid__block${index + 1}`], styles.grid__card)}>
										<img className={'mb-3'} src={item.icon} alt={item.title} width={42} height={42} />
										<p>{item.title}</p>
										<span>{item.body}</span>
									</div>
								))
							}
						</div>
						:
						<div className={cl('mb-5', styles.grid__line)}>
							<div>
								<h2 className={cl('mb-0', styles.grid__title)}>{t('for_employer')}</h2>
								<span className={cl(styles.grid__subtitle)}>{t('source_portal')}</span>
							</div>

							<div>
								<StyledSlider {...settings}>
									{array.map((item, index) => {
										return (
											<div className={'p-4'} key={index}>
												<div key={index}
														 className={cl(styles.grid__block, styles[`grid__block${index + 1}`], styles.grid__card)}>
													<img className={'mb-3'} src={item.icon} alt={item.title} width={42} height={42} />
													<p>{item.title}</p>
													<span>{item.body}</span>
												</div>
											</div>
										)
									})}
								</StyledSlider>
							</div>
						</div>
				}
			</div>
		</section>
	)
}

export default Employer
export const FILE_FORMAT = {
	doc: '.doc',
	docx: '.docx',
	pdf: '.pdf',
	jpg: '.jpg',
	jpeg: '.jpeg',
	png: '.png',
	xlsx: '.xlsx',
	txt: '.txt',
	imagePng: '.image/png',
}

export const BACKEND_FILE_TYPE = {
	HARMFULWORK: 'HARMFULWORK',
	COLLECTIVECONTRACT: 'COLLECTIVECONTRACT',
	INDUSTRYAGREEMENT: 'INDUSTRYAGREEMENT',
	TRADEUNION: 'TRADEUNION'
}
import * as types from './types'
export const initialColContract = {
	bin: '',
	enterpriseName: '',
	enterpriseNameKaz: '',
	collectiveContractNumber: '',
	dateFrom: '',
	dateTo: '',
	overtimePay: '',
	holidayPay: '',
	nightTimePay: '',
	combinationPositionPay: '',
	termCompensationPay: '',
	wayCompensationPay: '',
	wageIndexProcedure: '',
	firstCategoryRate: '',
	industryAgreementCoefficient: '',
	minCoefficient: '',
	maxCoefficient: '',
	firstGradeMin: '',
	firstGradeMax: '',
	secondGradeMin: '',
	secondGradeMax: '',
	thirdGradeMin: '',
	thirdGradeMax: '',
	fourthGradeMin: '',
	fourthGradeMax: '',
	fifthGradeMin: '',
	fifthGradeMax: '',
	sixthGradeMin: '',
	sixthGradeMax: '',
	seventhGradeMin: '',
	seventhGradeMax: '',
	eighthGradeMin: '',
	eighthGradeMax: '',
	workTime: [],
	relaxTime: [],
	securityFundingVolume: '',
	fundingAmount: '',
	dcolContractEnterpriseTypeCode: '',
	dcolContractOwnershipTypeCode: '',
	files: [],
	unions: [
		{
			representativeDtos: [
				{
					representativeFIO: '',
					representativeIin: '',
				},
			],
			unionBin: '',
			unionName: '',
			unionNameKaz: '',
			unionTypeCode: '',
		},
		{
			representativeDtos: [
				{
					representativeFIO: '',
					representativeIin: '',
				},
			],
			unionBin: '',
			unionName: '',
			unionNameKaz: '',
			unionTypeCode: '',
		},
		{
			representativeDtos: [
				{
					representativeFIO: '',
					representativeIin: '',
				},
			],
			unionBin: '',
			unionName: '',
			unionNameKaz: '',
			unionTypeCode: '',
		},
	],
	extraGuarantee: [
		{
			code: '',
			value: '',
		},
	],
	//Информация о размерах тарифных ставок,оклада и надбавок(доплат)
	positions: [{
		gradeList: [{
			gradeCoeff: '',
			gradeLevel: 1,
			salarySupplement: '',
			tariffRate: '',
		}],
		positionCode: '',
		workConditionsCode: '',
	}],
	//Форма собственности
	industryCodes : [],
}

const initialState = {
	isLoading: false,
	isSuccess: false,
	error: null,
	colContract: {
		data: initialColContract,
		isLoading: false,
		isSuccess: false,
		hasError: false,
	},
	colContracts: [],
	colXML: null,
	pageable: null,
	totalPages: 0,
	totalElements: 0,
	last: false,
	number: 0,
	sort: null,
	size: 0,
	first: false,
	numberOfElements: 0,
	empty: false,
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.TOGGLE_ERROR:
			return { ...state, error: payload }
		case types.SET_COL_CONTRACT:
			return { ...state, colContract: { ...state.colContract, data: payload } }
		case types.SET_COL_CONTRACT_LOADING:
			return { ...state, colContract: { ...state.colContract, isLoading: payload } }
		case types.SET_COL_CONTRACT_SUCCESS:
			return { ...state, colContract: { ...state.colContract, isSuccess: payload } }
		case types.SET_COL_CONTRACTS:
			return {
				...state,
				colContracts: payload.content,
				pageable: payload.pageable,
				totalPages: payload.totalPages,
				totalElements: payload.totalElements,
				last: payload.last,
				number: payload.number,
				sort: payload.sort,
				size: payload.size,
				first: payload.first,
				numberOfElements: payload.numberOfElements,
				empty: payload.empty,
			}
		case types.SET_INITIAL_COL_CONTRACT:
			return { ...state, contract: initialColContract }
		case types.SET_COL_XML:
			return { ...state, colXML: payload }
		case types.RESET_COL_XML:
			return {
				...state,
				colXML: null,
			}
		default:
			return state
	}
}

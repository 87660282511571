import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { request } from '@/shared/api/request'
import { message } from 'antd'
import PreHeader from '@/components/home/components/PreHeader'
import Header from '@Root/components/Header/index'
import styles from './styles.module.scss'
import { Title } from '@/components/Typography'
import { DataGridTable } from '@/ui'
import { Type } from './TypeDoc'
import { getCurrentLanguage } from '@/i18next'
import { InfoGreen } from '@app/icons'
import Footer from '@features/home/Footer'

export const CheckDocumentPage = () => {
	const params = useParams()
	const { t } = useTranslation()
	const [data, setData] = useState(null)
	const lang = getCurrentLanguage()
	const langOption = lang === 'rus' ? 'nameRu' : 'nameKz'
	// Получите соответствующее значение из объекта Type
	const textValue = Type[params.type]

	const checkDoc = async (params) => {
		try {
			if (params) {
				const result = await request({
					url: `/contract/free/checkDoc/${params.id}`,
					method: 'GET',
					params: { type: params.type },
				})
				setData(result)
			}
		} catch (error) {
			const err = error?.response?.status === 500 ? t('error_checks') : error?.response?.data?.message
			message.error(err ? err : t('error_checks_system'), 5)
		}
	}

	useEffect(() => {
		if (params) {
			checkDoc(params)
		}
	}, [])

	const contactInformation = [
		{ title: t('document_checks_name'), data: t(textValue) },
		{ title: t('document_ID'), data: data?.externalId },
		{ title: t('document_status_sign'), data: data?.state?.[langOption] },
		{
			title: t('document_number_date'),
			data:
				lang === 'rus'
					? '№' + data?.contractNumber + ' от ' + data?.registerDate + 'г.'
					: data?.registerDate + 'ж. ' + 'бастап №' + data?.contractNumber,
		},
		{
			title: t('document_sign_employer'),
			data:
				data?.employerName +
				'\n' +
				data?.state?.[langOption] +
				': ' +
				data?.signEmployerName +
				'\n' +
				t('document_time_sign') +
				': ' +
				data?.signEmployerDate,
		},
		{
			title: t('document_sign_employee'),
			data: data?.fullName + '\n' + t('document_time_sign') + ': ' + data?.signEmployeeDate,
		},
	]
	return (
		<>
			<Header active="check_document" dark />
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<div className={`${styles.page} container`}>
				<Title level={1}>{t('document_verification')}</Title>
				{contactInformation ? (
					<DataGridTable
						hideSubTitle
						information={contactInformation}
						titleClass={styles.tableTitleCell}
						rowClass={styles.tableInfoCell}
						dataClass={styles.dataClass}
					/>
				) : null}
				<div className={styles.note}>
					<div className={styles.noteTitle}>
						<InfoGreen />
						<div>{t('document_note_title')}</div>
					</div>
					{t('document_note')}
				</div>
			</div>
			<Footer />
		</>
	)
}

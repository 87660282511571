import { contractsActions, contractsThunks } from '@/entity'
import { handbookThunks } from '@/entity/handbook'
import { api } from '@/shared'
import { message } from 'antd'
import { actions } from './actions'
import { getDictionaries } from '@/shared/api/rest/dict/getDictionaries'
import { filterOneKato, getCategoryRelation } from '@/entity/handbook/thunks'

export const getContract = (id, isLocal, localOrRemote = false) => {
	return async (dispatch) => {
		try {
			if (!!id) {
				dispatch(contractsActions.setContractLoading(true))
				const content = localOrRemote ? await api.contracts.getlocalOrRemote(id) : await api.contracts.get(id, isLocal)
				const { ddistrictCode, dregionCode, dlocalityCode, dpositionCode, dcountryCode } = content
				let position, district, region, locality, country
				try {
					;[district, region, locality] = await Promise.all(
						[ddistrictCode, dregionCode, dlocalityCode].map(async (item) =>
							item ? await filterOneKato(item) : Promise.resolve(null)
						)
					)
				} catch (err) {
					dispatch(contractsActions.setContractLoading(false))
				}

				try {
					position = dpositionCode ? await api.shared.getPosition({ code: dpositionCode }) : null
				} catch (err) {
					dispatch(contractsActions.setContractLoading(false))
				}

				try {
					country = dcountryCode ? await api.shared.getCountry({ code: dcountryCode }) : null
				} catch (err) {
					dispatch(contractsActions.setContractLoading(false))
				}
				dispatch(actions.setContract({ ...content, district, region, locality, position, country }))
			}
		} catch (error) {
			dispatch(contractsActions.setContractLoading(false))
		} finally {
			dispatch(contractsActions.setContractLoading(false))
		}
	}
}

export const setAdditionalContract = (contract) => {
	return async (dispatch) => {
		try {
			dispatch(actions.setAdditionalContractIsLoading(true))
			const { dpositionCode, dcountryCode, ddistrictCode, dregionCode, dlocalityCode } = contract
			let position = null
			try {
				position = dpositionCode ? await api.shared.getPosition({ code: dpositionCode }) : null
			} catch (err) {}
			let district, region, locality, country
			try {
				;[district, region, locality] = await Promise.all(
					[ddistrictCode, dregionCode, dlocalityCode].map(async (item) =>
						item ? await filterOneKato(item) : Promise.resolve(null)
					)
				)
			} catch (err) {}
			try {
				country = dcountryCode ? await api.shared.getCountry({ code: dcountryCode }) : null
			} catch (err) {}
			dispatch(
				actions.setAdditionalContractId({
					...contract,
					position,
					country,
					district,
					region,
					locality,
				})
			)
		} catch (err) {
		} finally {
			dispatch(actions.setAdditionalContractIsLoading(false))
		}
	}
}

export const saveAdditionalContract = (data, isEdit = false) => {
	return async (dispatch, getState) => {
		try {
			dispatch(actions.setAdditionalContractIsLoading(true))
			const state = getState()
			const { bin, id: contractId, externalId: contractExternalId, iin } = state?.contracts.contract.data || {}
			const response = await api.contracts.createAdditionalContractXml({
				bin,
				contractId,
				contractExternalId,
				iin,
				...data,
			})
			dispatch(actions.setAdditionalContractXml(response))
		} catch (err) {
			message.error(err.response ? err.response.data.message : 'Неизвестная ошибка', 10)
		} finally {
			dispatch(actions.setAdditionalContractIsLoading(false))
		}
	}
}

export const saveLocalAdditionalContract = (data, page, pageSize, filters = {}) => {
	return async (dispatch, getState) => {
		try {
			dispatch(actions.setAdditionalContractIsLoading(true))
			const state = getState()
			const { bin, id: contractId, externalId: contractExternalId, iin } = state?.contracts.contract.data || {}
			await api.contracts.createLocalAdditionalAgreement({
				bin,
				contractId,
				contractExternalId,
				iin,
				...data,
			})
			const { externalId, isLocalContract } = state.contracts.contract.data
			dispatch(contractsThunks.getContract(externalId, isLocalContract))
			dispatch(contractsThunks.getContracts(filters, page, pageSize))
			dispatch(actions.setAdditionalContractSubmitted())
			message.success('Операция успешно выполнена', 10)
		} catch (err) {
			message.error(err.response ? err.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(actions.setAdditionalContractIsLoading(false))
		}
	}
}

export const sendAdditionalContract = (signedXml, data, page, pageSize, filters) => {
	return async (dispatch, getState) => {
		try {
			dispatch(actions.setAdditionalContractIsLoading(true))
			const state = getState()
			const { externalId, id, contractNumber, dateFrom, bin, iin, isLocalContract } = state.contracts.contract.data
			const { companyName } = state.company?.company
			const paramMap = {
				addname: data?.contractNum,
				adddate: data?.dateFrom,
				cntrname: contractNumber,
				cntrdate: dateFrom,
				orgfrom: companyName,
				fromBin: bin,
				toIin: iin,
				val1: externalId,
			}
			await api.contracts.sendSignedAdditionalContract({
				signedXml,
				externalId,
				entityId: id,
				paramMap,
			})
			dispatch(contractsThunks.getContract(externalId, isLocalContract))
			dispatch(actions.setAdditionalContractSubmitted())
			dispatch(actions.setAdditionalContractIsLoading(false))
			dispatch(contractsThunks.getContracts(filters, page, pageSize))
			message.success('Операция успешно выполнена', 10)
		} catch (err) {
			message.error(err.response ? err.response.data.message : 'Неизвестная ошибка')
			dispatch(contractsActions.setAdditionalContractXml(null))
			dispatch(actions.setAdditionalContractIsLoading(false))
		} finally {
			dispatch(actions.setAdditionalContractIsLoading(false))
		}
	}
}

export const deleteAdditionalContract = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(actions.setAdditionalContractIsLoading(true))
			const state = getState()
			const { contract } = state.contracts.additionalContract
			const response = await api.contracts.getDeleteAdditionalContractXml(contract)
			dispatch(actions.setAdditionalContractXml(response))
		} catch (err) {
			message.error(err.response ? err.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(actions.setAdditionalContractIsLoading(false))
		}
	}
}

export const deleteLocalAdditionalContract = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(actions.setAdditionalContractIsLoading(true))
			const state = getState()
			const { externalId } = state.contracts.additionalContract.contract
			await api.contracts.deleteLocalAdditionalAgreement(externalId)
			const { externalId: contractExternalId, isLocalContract } = state.contracts.contract.data
			dispatch(contractsThunks.getContract(contractExternalId, isLocalContract))
			dispatch(actions.setAdditionalContractSubmitted())
			message.success('Операция успешно выполнена', 10)
		} catch (err) {
			message.error(err.response ? err.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(actions.setAdditionalContractIsLoading(false))
		}
	}
}

export const getContracts = (params, page, size) => {
	return async (dispatch) => {
		try {
			dispatch(contractsActions.toggleError(null))
			dispatch(contractsActions.toggleLoading(true))
			dispatch(contractsActions.setParams(params))
			const data = await api.contracts.getAll(params, { page, size })
			dispatch(contractsActions.setContracts(data))
			dispatch(contractsActions.toggleSuccess(true))
			dispatch(contractsActions.toggleLoading(false))
		} catch (error) {
			dispatch(contractsActions.toggleError(error))
			dispatch(contractsActions.toggleSuccess(false))
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(contractsActions.toggleLoading(false))
		}
	}
}

export const sendSignedContract = (signedXml, force = false, data) => {
	return async (dispatch, getState) => {
		try {
			dispatch(contractsActions.setContractLoading(true))
			const state = getState()
			const { companyName } = state.company?.company
			const contract = state.contracts.contract.data
			const isEdit = state.contracts.isEdit
			const { externalId, contractNumber, registerDate, bin, iin } = data || contract
			const paramMap = {
				cntrname: contractNumber,
				cntrdate: registerDate,
				orgfrom: companyName,
				fromBin: bin,
				toIin: iin,
				val1: externalId,
				isEdit,
			}
			const created = await api.contracts.sendContract({ signedXml, paramMap })
			if (created.success) {
				if (force) {
					const { params, size } = state.contracts
					dispatch(contractsThunks.getContracts(params, 0, size))
				} else {
					const { externalId: contractExternalId, isLocalContract } = state.contracts.contract.data
					dispatch(contractsThunks.getContract(contractExternalId, isLocalContract))
				}
				dispatch(contractsActions.setContractSuccess(true))
				message.success('Данные отправлены успешно', 10)
			} else {
				dispatch(contractsActions.setContractSuccess(false))
			}
		} catch (error) {
			dispatch(contractsActions.setContractSuccess(false))
			dispatch(contractsActions.setContractLoading(false))
			message.error('Ошибка отправки данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
			dispatch(contractsActions.setContractError(true))
			dispatch(contractsActions.setContractLoading(false))
		} finally {
			dispatch(contractsActions.setContractLoading(false))
		}
	}
}

export const createLocalContract = (data, isEdit = false) => {
	return async (dispatch, getState) => {
		try {
			dispatch(contractsActions.setContractLoading(true))
			if (data.isResident) data.foreignLaborInfo = null
			const created = await api.profile.createContract(data)
			if (created.success) {
				const state = getState()
				if (isEdit) {
					const { contracts } = state.contracts
					dispatch(
						contractsActions.setContracts({
							...state.contracts,
							content: contracts.map((contract) => {
								if (contract.externalId === data.externalId) contract.externalId = created.data.externalId
								return contract
							}),
						})
					)
					dispatch(contractsActions.setContract({ ...created.data }))
				}
				if (!isEdit) {
					const { params, size } = state.contracts
					dispatch(contractsThunks.getContracts(params, 0, size))
				}
				dispatch(contractsActions.setContractSuccess(true))
				message.success('Операция выполнена успешно', 10)
			} else {
				dispatch(contractsActions.setContractSuccess(false))
			}
		} catch (error) {
			dispatch(contractsActions.setContractSuccess(false))
			dispatch(contractsActions.setContractLoading(false))
			message.error('Ошибка сохранения данных:' + error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(contractsActions.setContractLoading(false))
		}
	}
}

export const deleteLocalContract = (externalId) => {
	return async (dispatch, getState) => {
		try {
			dispatch(actions.setContractLoading(true))
			const state = getState()
			const { contracts } = state.contracts
			const created = await api.contracts.deleteLocalContract(externalId)
			if (created.success) {
				message.success('Данные успешно удалены', 10)
				dispatch(contractsActions.setContractSuccess(true))
				dispatch(
					contractsActions.setContracts({
						...state.contracts,
						content: contracts.filter((contract) => contract.externalId !== externalId),
					})
				)
			} else {
				dispatch(contractsActions.setContractSuccess(false))
			}
		} catch (error) {
			dispatch(contractsActions.setContractSuccess(false))
			message.error('Ошибка удаления данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
		} finally {
			dispatch(actions.setContractLoading(false))
		}
	}
}

export const sendTerminate = (signedXml) => {
	return async (dispatch, getState) => {
		try {
			dispatch(actions.setTerminationIsLoading(true))
			const state = getState()
			const { id, externalId, contractNumber, dateFrom, bin, iin } = state.contracts.contract.data
			const { companyName } = state.company?.company
			const paramMap = {
				cntrname: contractNumber,
				cntrdate: dateFrom,
				orgfrom: companyName,
				fromBin: bin,
				toIin: iin,
				val1: externalId,
			}
			await api.contracts.terminate({
				signedXml,
				externalId,
				entityId: id,
				paramMap,
			})
			dispatch(actions.setTerminationIsSuccess(true))
			message.success('Прекращение/расторжение договора успешно выполнено', 5)
			dispatch(actions.setContractSuccess(true))
		} catch (error) {
			message.error(
				'Ошибка прекращения/расторжения договора:' +
					(error.response ? error.response.data.message : 'Неизвестная ошибка')
			)
			dispatch(actions.resetTermination())
			message.error('Ошибка удаления данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
			dispatch(actions.setContractSuccess(false))
		} finally {
			dispatch(actions.setTerminationIsLoading(false))
		}
	}
}

export const generateRemoveXML = (externalId) => {
	return async (dispatch) => {
		try {
			dispatch(actions.setContractLoading(true))
			const contractXml = await api.contracts.deleteXml(externalId)
			dispatch(actions.setRemoveXML(contractXml))
		} catch (error) {
			message.error('Ошибка удаления данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
			dispatch(actions.setContractSuccess(false))
		} finally {
			dispatch(actions.setContractLoading(false))
		}
	}
}

export const generateRecoverXML = (data) => {
	return async (dispatch) => {
		try {
			dispatch(actions.setContractLoading(true))
			const xml = await api.contracts.generateRecoverXml(data)
			dispatch(actions.setRecoverXML({ data: xml }))
		} catch (error) {
			message.error('Ошибка удаления данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
			dispatch(actions.setContractSuccess(false))
		} finally {
			dispatch(actions.setContractLoading(false))
		}
	}
}

export const recover = (data) => {
	return async (dispatch, getState) => {
		try {
			dispatch(actions.setContractLoading(true))
			const state = getState()
			const { contractNumber, dateFrom, bin, iin } = state.contracts?.contract.data
			const { companyName } = state.company?.company
			const paramMap = {
				cntrname: contractNumber,
				cntrdate: dateFrom,
				orgfrom: companyName,
				fromBin: bin,
				toIin: iin,
			}
			const response = await api.contracts.recoverXml({ ...data, paramMap })
			dispatch(actions.setRecoverXML({ data: null, open: false, response, error: null }))
		} catch (error) {
			dispatch(
				actions.setRecoverXML({
					data: null,
					open: false,
					error: error.response ? error.response.data.message : 'Неизвестная ошибка',
					response: null,
				})
			)
		} finally {
			dispatch(actions.setContractLoading(false))
		}
	}
}

export const generateExternalRemoveXML = (data) => {
	return async (dispatch) => {
		try {
			dispatch(actions.setContractLoading(true))
			const contractXml = await api.contracts.deleteExternalXml(data)
			dispatch(actions.setRemoveXML(contractXml))
		} catch (error) {
			message.error('Ошибка удаления данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
			dispatch(actions.setContractSuccess(false))
		} finally {
			dispatch(actions.setContractLoading(false))
		}
	}
}

export const edit = (data) => {
	return async (dispatch, getState) => {
		try {
			dispatch(contractsActions.setContractLoading(true))
			const state = getState()
			const { externalId, contractNumber, dateFrom, bin, iin } = state.contracts?.contract.data
			const { companyName } = state.company?.company
			const paramMap = {
				cntrname: contractNumber,
				cntrdate: dateFrom,
				orgfrom: companyName,
				fromBin: bin,
				toIin: iin,
				isEdit: true,
			}
			await api.contracts.sendContract({ externalId, signedXml: data, paramMap })
			dispatch(contractsThunks.getContract(externalId, false))
			message.success('Данные обновлены успешно', 10)
			dispatch(contractsActions.setContractSuccess(true))
			dispatch(contractsActions.setContractLoading(false))
		} catch (error) {
			message.error('Ошибка обновления данных:' + error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(actions.resetEditing())
			dispatch(contractsActions.setContractLoading(false))
		} finally {
			dispatch(contractsActions.setContractLoading(false))
		}
	}
}

export const generateEditXML = (data) => {
	return async (dispatch) => {
		try {
			dispatch(contractsActions.setContractLoading(true))
			if (data.isResident) data.foreignLaborInfo = null
			const contractXml = await api.contracts.getEditXml(data)
			dispatch(actions.setEditXML(contractXml))
		} catch (err) {
			dispatch(contractsActions.setContractLoading(false))
		} finally {
			dispatch(contractsActions.setContractLoading(false))
		}
	}
}

export const generateLocalEditXML = (externalId) => {
	return async (dispatch) => {
		try {
			dispatch(actions.setContractLoading(true))
			const contractXml = await api.contracts.getXml(externalId)
			dispatch(actions.setEditXML(contractXml))
		} catch (error) {
			message.error('Ошибка:' + error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(actions.setContractError(true))
		} finally {
			dispatch(actions.setContractLoading(false))
		}
	}
}

export const remove = (data) => {
	return async (dispatch, getState) => {
		try {
			dispatch(actions.setContractLoading(true))
			const state = getState()
			const { contractNumber, dateFrom, bin, iin } = state.contracts.contract.data
			const { companyName } = state.company?.company
			const paramMap = {
				cntrname: contractNumber,
				cntrdate: dateFrom,
				orgfrom: companyName,
				fromBin: bin,
				toIin: iin,
			}
			await api.contracts.remove({ ...data, paramMap })
			message.success('Данные удалены успешно', 10)
			dispatch(actions.setRemoveXML(null))
			const { params, size } = state.contracts
			dispatch(contractsThunks.getContracts(params, 0, size))
			dispatch(actions.setContractSuccess(true))
		} catch (error) {
			message.error('Ошибка:' + error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(actions.setContractSuccess(false))
		} finally {
			dispatch(actions.setContractLoading(false))
		}
	}
}

export const getOptions = () => {
	return (dispatch, getState) => {
		const {
			contractTerms,
			positions,
			jobTypes,
			workingTimeModes,
			countries,
			districts,
			professionalSkills,
			personalQualities,
			remoteWorkTypes,
			contractTypes,
			sendStatuses,
			esutdRegErrors,
			socialLeaveTypes,
			restoreOrganizationTypes,
			restoreOrganizationCourts,
			positionsByKnz,
			contractStatuses,
			contractStates,
			workActivityTypes,
			workCondition,
			extraGuaranteesCollectiveContract,
			unionTypeCollectiveContract,
			workActivityTypeGroups,
			nonresidentAttractingBasis,
			nonresidentEducation,
			ccEnterpriseType,
			ccOwnershipType,
			establishedPostPositionCategoryRelation,
			industryAgreement
		} = getState().handbook

		const terminationReasons = getState().contracts.terminationReasons

		if (!contractStatuses.length) dispatch(handbookThunks.getContractStatuses())
		if (!contractStates.length) dispatch(handbookThunks.getContractStates())
		if (!contractTerms.length) dispatch(handbookThunks.getContractTerms())
		if (!positionsByKnz.length) dispatch(handbookThunks.getPositionsByKnz())
		if (!workCondition.length) dispatch(handbookThunks.getWorkCondition())
		if (!extraGuaranteesCollectiveContract.length) dispatch(handbookThunks.getExtraGuaranteesCollectiveContract())
		if (!unionTypeCollectiveContract.length) dispatch(handbookThunks.getUnionTypeCollectiveContract())
		if (!socialLeaveTypes.length) dispatch(handbookThunks.getSocialLeaveTypes())
		if (!restoreOrganizationTypes.length) dispatch(handbookThunks.getRestoreOrganizationTypes())
		if (!restoreOrganizationCourts.length) dispatch(handbookThunks.getRestoreOrganizationCourts())
		if (!positions.length) dispatch(handbookThunks.getPositions())
		if (!jobTypes.length) dispatch(handbookThunks.getJobTypes())
		if (!workingTimeModes.length) dispatch(handbookThunks.getWorkingTimeModes())
		if (!countries.length) dispatch(handbookThunks.getCountries())
		if (!districts.length) dispatch(handbookThunks.getDistrictsAuth())
		if (!professionalSkills.length) dispatch(handbookThunks.getProfessionalSkills())
		if (!personalQualities.length) dispatch(handbookThunks.getPersonalQualities())
		if (!remoteWorkTypes.length) dispatch(handbookThunks.getRemoteWorkTypes())
		if (!sendStatuses.length) dispatch(handbookThunks.sendStatuses())
		if (!esutdRegErrors.length) dispatch(handbookThunks.esutdRegErrors())
		if (!contractTypes.length) dispatch(handbookThunks.getContractTypes())
		if (!terminationReasons?.length) dispatch(contractsThunks.getTerminationReasons())
		if (!workActivityTypes.length) dispatch(handbookThunks.getWorkActivityTypes())
		if (!workActivityTypeGroups.length) dispatch(handbookThunks.getWorkActivityGroup())
		if (!nonresidentAttractingBasis.length) dispatch(handbookThunks.getNonresidentAttractingBasis())
		if (!nonresidentEducation.length) dispatch(handbookThunks.getNonresidentEducation())
		if (!ccEnterpriseType.length) dispatch(handbookThunks.getCcEnterpriseType())
		if (!ccOwnershipType.length) dispatch(handbookThunks.getCcOwnershipType())
		if (!industryAgreement.length) dispatch(handbookThunks.getIndustryAgreement())
		if (!establishedPostPositionCategoryRelation.length) dispatch(handbookThunks.getCategoryRelation())
	}
}

export const getViewAllOptions = () => {
	return (dispatch, getState) => {
		const state = getState()
		const { contractStatuses, contractStates } = state.handbook

		const activeRole = state.user.activeRole || JSON.parse(localStorage.getItem('activeRole'))

		const params = {
			isEmployer: activeRole == 'ROLE_EMPLOYER',
		}

		if (!contractStatuses.length) dispatch(handbookThunks.getContractStatuses(params))
		if (!contractStates.length) dispatch(handbookThunks.getContractStates(params))
	}
}

export const updateInitialContract = () => {
	return (dispatch, getState) => {
		const state = getState()
		const user = state.user.user
		const company = state.company.companies[0]
		dispatch(actions.setContractFields({ bin: user?.iin, companyName: company?.companyName }))
	}
}

export const getTerminationReasons = () => {
	return async (dispatch) => {
		try {
			dispatch(contractsActions.toggleLoading(true))
			const { content } = await getDictionaries('termReason', { size: 999 })
			dispatch(actions.setTerminationReasons(content))
		} catch (err) {
			dispatch(contractsActions.toggleLoading(false))
		} finally {
			dispatch(contractsActions.toggleLoading(false))
		}
	}
}

export const submitTerminationData = (data) => {
	return async (dispatch) => {
		try {
			dispatch(actions.setTerminationIsLoading(true))
			const { bin, externalId } = data
			const response = await api.contracts.terminateContract({
				...data,
				bin,
				externalId,
			})
			dispatch(actions.setTerminateXML(response))
		} catch (error) {
			message.error('Ошибка обновления данных:' + error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(actions.resetTermination())
		} finally {
			dispatch(actions.setTerminationIsLoading(false))
		}
	}
}

export const getDataByBin = (params) => {
	return async (dispatch) => {
		try {
			dispatch(actions.getDataByBinStart())
			const response = await api.contracts.getDataByBin(params)
			dispatch(actions.getDataByBin(response))
		} catch (error) {
			dispatch(actions.getDataByBinError(error?.response?.data?.message))
		}
	}
}

export const getEmployerPermissionByBin = (bin) => {
	return async (dispatch) => {
		try {
			dispatch(actions.getEmployerPermissionByBinStart())
			const response = await api.contracts.getEmployerPermissionByBin(bin)
			dispatch(actions.getEmployerPermissionByBin(response))
		} catch (error) {
			dispatch(actions.getEmployerPermissionByBinError(error?.response?.data?.message))
		}
	}
}

export const getFLData = (data) => {
	return async (dispatch) => {
		try {
			const response = await api.contracts.getFLData(data)
			dispatch(actions.getFLData(response))
		} catch (error) {
			dispatch(actions.reorganizeError(error?.response?.data))
		}
	}
}

export const getEmployersListToChange = (data, pagination) => {
	return async (dispatch) => {
		try {
			const response = await api.contracts.getEmployersListToChange(data, pagination)
			dispatch(actions.getEmployersListToChange(response))
		} catch (err) {}
	}
}

export const getXmlForUpdateIin = (data) => {
	return async (dispatch) => {
		try {
			const response = await api.contracts.getXmlForUpdateIin(data)
			dispatch(actions.getXmlForUpdateIin(response))
		} catch (error) {
			dispatch(actions.reorganizeError(error?.response?.data))
		}
	}
}

export const getReorganizeXml = (data) => {
	return async (dispatch) => {
		try {
			dispatch(actions.getXmlToReorganizeLoading())
			const response = await api.contracts.getReorganizeXml(data)
			dispatch(actions.getReorganizeXml(response))
		} catch (error) {
			dispatch(actions.reorganizeError(error?.response?.data))
		}
	}
}

export const sendSignedXml = (data) => {
	return async (dispatch) => {
		try {
			dispatch(actions.getXmlToReorganizeLoading())
			const response = await api.contracts.sendSignedXml(data)
			if (response[0].success) {
				dispatch(actions.sendSignedXml(response))
			} else {
				dispatch(actions.setReorganizeSuccess(response))
			}
		} catch (error) {
			dispatch(actions.reorganizeError(error?.response?.data))
		}
	}
}

export const sendUpdateIinXml = (data) => {
	return async (dispatch) => {
		try {
			const response = await api.contracts.sendUpdateIinXml(data)
			dispatch(actions.sendUpdateIinXml(response))
		} catch (error) {
			dispatch(actions.setErrorUpdateIin(error?.response?.data))
		}
	}
}

export const deleteSocialLeave = (socialLeave) => {
	return async (dispatch, getState) => {
		try {
			dispatch(contractsActions.setSocialLeaveIsLoading(true))
			const state = getState()
			const {
				bin,
				externalId: contractExternalId,
				dcontractState,
				entryDate,
				iin,
				id,
			} = state.contracts.contract.data || {}
			const xml = await api.contracts.deleteSocialLeaveXml({
				...socialLeave,
				id,
				bin,
				contractExternalId,
				dcontractState,
				entryDate,
				iin,
				status: socialLeave.status?.code ? 'SIGNED' : 'NOT_SIGNED',
				dleaveTypeCode: socialLeave.dleaveTypeCode.value,
			})
			dispatch(contractsActions.setSocialLeaveXML(xml))
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(contractsActions.setSocialLeaveIsLoading(false))
		}
	}
}

export const deleteLocalSocialLeave = (externalId) => {
	return async (dispatch, getState) => {
		try {
			dispatch(contractsActions.setSocialLeaveIsLoading(true))
			const state = getState()
			await api.contracts.deleteLocalSocialLeave(externalId)
			const { externalId: contractExternalId, isLocalContract } = state.contracts.contract.data || {}
			dispatch(contractsThunks.getContract(contractExternalId, isLocalContract))
			message.success('Операция успешно выполнена', 10)
			dispatch(contractsActions.setSocialLeaveIsSuccess(true))
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(contractsActions.setSocialLeaveIsLoading(false))
		}
	}
}

export const sendSignedSocialLeave = (signedXml, paramMap, page = 0, pageSize = 10, filters) => {
	return async (dispatch, getState) => {
		try {
			dispatch(contractsActions.setSocialLeaveIsLoading(true))
			const state = getState()
			const { externalId, id, contractNumber, dateFrom, bin, iin, isLocalContract } =
				state.contracts.contract.data || {}
			const { companyName } = state.company.company
			await api.contracts.sendSignedSocialLeave({
				signedXml,
				externalId,
				entityId: id,
				paramMap: {
					cntrname: contractNumber,
					cntrdate: dateFrom,
					orgfrom: companyName,
					fromBin: bin,
					toIin: iin,
					// leavetype: formData?.dleaveTypeCode || socialLeave?.dleaveTypeCode?.value,
					...paramMap,
				},
			})
			dispatch(contractsThunks.getContract(externalId, isLocalContract))
			dispatch(contractsActions.setSocialLeaveIsSuccess(true))
			message.success('Операция выполнена успешно', 10)
			dispatch(contractsThunks.getContracts(filters, page, pageSize))
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(contractsActions.resetSocialLeave())
		} finally {
			dispatch(contractsActions.setSocialLeaveIsLoading(false))
		}
	}
}

export const createSocialLeave = (data) => {
	return async (dispatch, getState) => {
		try {
			dispatch(contractsActions.setSocialLeaveIsLoading(true))
			const state = getState()
			const {
				bin,
				externalId: contractExternalId,
				dcontractState,
				entryDate,
				iin,
				id,
			} = state.contracts.contract.data || {}
			const xml = await api.contracts.createSocialLeaveXml({
				...data,
				id,
				bin,
				contractExternalId,
				dcontractState,
				entryDate,
				iin,
			})
			dispatch(contractsActions.setSocialLeaveXML(xml))
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(contractsActions.resetSocialLeave())
		} finally {
			dispatch(contractsActions.setSocialLeaveIsLoading(false))
		}
	}
}

export const createLocalSocialLeave = (data) => {
	return async (dispatch, getState) => {
		try {
			dispatch(contractsActions.setSocialLeaveIsLoading(true))
			const state = getState()
			const {
				bin,
				externalId: contractExternalId,
				dcontractState,
				entryDate,
				iin,
				id,
				isLocalContract,
			} = state.contracts.contract.data || {}
			await api.contracts.createLocalSocialLeave({
				id,
				bin,
				contractExternalId,
				dcontractState,
				entryDate,
				iin,
				...data,
			})
			dispatch(contractsThunks.getContract(contractExternalId, isLocalContract))
			dispatch(contractsActions.setSocialLeaveIsSuccess(true))
			message.success('Операция успешно выполнена', 10)
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(contractsActions.setSocialLeaveIsLoading(false))
		}
	}
}

export const createProlongation = (data) => {
	return async (dispatch) => {
		try {
			dispatch(contractsActions.setProlongationIsLoading(true))
			const xml = await api.contracts.getProlongationXml(data)
			dispatch(contractsActions.setProlongationXML(xml))
		} catch (error) {
			message.error('Ошибка обновления данных:' + error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(contractsActions.resetProlongation())
		} finally {
			dispatch(contractsActions.setProlongationIsLoading(false))
		}
	}
}

export const sendProlongation = (signedXml) => {
	return async (dispatch, getState) => {
		try {
			dispatch(contractsActions.setProlongationIsLoading(true))
			const state = getState()
			const contractData = state?.contracts.contract.data || {}
			await api.contracts.sendSignedProlongationXml({
				signedXml: signedXml || null,
				externalId: contractData?.externalId || null,
				entityId: contractData?.id || null,
			})
			message.success('Пролонгация прошла успешно!', 10)
			dispatch(contractsThunks.getContract(contractData?.externalId, false))
			dispatch(contractsActions.setProlongationIsSuccess(true))
		} catch (error) {
			message.error(
				'Ошибка обновления данных:' + error?.response ? error?.response.data?.message : 'Неизвестная ошибка'
			)
			dispatch(contractsActions.resetProlongation())
		} finally {
			dispatch(contractsActions.setProlongationIsLoading(false))
		}
	}
}

export const getXmlToSignSome = (body, params) => async (dispatch) => {
	try {
		dispatch(contractsActions.getXmlToSignSomeLoading())
		const response = await api.packageLoading.getXmlToSignSome(body, params)
		dispatch(contractsActions.getXmlToSignSome(response))
	} catch (error) {
		dispatch(contractsActions.getXmlToSignSomeError(error?.response?.data?.message || 'Неизвестная ошибка'))
		message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
	}
}

export const sendSignedXmlPackageSome =
	(body, params, filters = {}, page = 0, pageSize = 10) =>
	async (dispatch, getState) => {
		try {
			dispatch(contractsActions.getXmlToSignSomeLoading())
			const response = await api.packageLoading.sendSignedXmlPackageSome(body, params)
			dispatch(contractsActions.sendSignedXmlPackageSome(response))
			dispatch(contractsThunks.getContracts(filters, page, pageSize))
			const state = getState()
			const { externalId } = state.contracts.contract.data || {}
			if (externalId) {
				dispatch(contractsThunks.getContract(externalId, false))
			}
		} catch (error) {
			dispatch(contractsActions.getXmlToSignSomeError(error?.response?.data?.message || 'Неизвестная ошибка'))
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		}
	}

export const deleteContracts = (body, params) => async (dispatch) => {
	try {
		dispatch(contractsActions.getXmlToSignSomeLoading())
		const response = await api.packageLoading.deleteContracts(body, params)
		dispatch(contractsActions.deleteContracts(response || {}))
	} catch (error) {
		dispatch(contractsActions.getXmlToSignSomeError(error?.response?.data?.message || 'Неизвестная ошибка'))
		message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
	}
}

export const getEtd = (payload) => async (dispatch) => {
	try {
		dispatch(
			contractsActions.setCurrentEtd({
				data: null,
				loading: true,
				error: null,
			})
		)
		const response = await api.contracts.getEtd(payload)
		dispatch(
			contractsActions.setCurrentEtd({
				data: response,
				loading: false,
				error: null,
			})
		)
	} catch (error) {
		const err = error?.response?.data?.message || 'Неизвестная ошибка'
		dispatch(
			contractsActions.setCurrentEtd({
				data: null,
				loading: false,
				error: err,
			})
		)
		message.error(err ? err : 'Неизвестная ошибка')
	}
}

export const getEtdEdit = (payload) => async (dispatch) => {
	try {
		dispatch(
			contractsActions.setCurrentEtdEdit({
				data: null,
				loading: true,
				error: null,
			})
		)
		const response = await api.contracts.getEtdEdit(payload)
		dispatch(
			contractsActions.setCurrentEtdEdit({
				data: response,
				loading: false,
				error: null,
			})
		)
	} catch (error) {
		const err = error?.response?.data?.message || 'Неизвестная ошибка'
		dispatch(
			contractsActions.setCurrentEtdEdit({
				data: null,
				loading: false,
				error: err,
			})
		)
		message.error(err ? err : 'Неизвестная ошибка')
	}
}

export const sendToEmployee = (payload) => async (dispatch) => {
	try {
		dispatch(
			contractsActions.sendToEmployee({
				data: null,
				loading: true,
				error: null,
			})
		)
		const response = await api.contracts.sendtoEmployee(payload)
		dispatch(
			contractsActions.sendToEmployee({
				data: response,
				loading: false,
				error: null,
			})
		)
	} catch (error) {
		const err = error?.response?.data?.message || 'Неизвестная ошибка'
		dispatch(
			contractsActions.sendToEmployee({
				data: null,
				loading: false,
				error: err,
			})
		)
		message.error(err ? err : 'Неизвестная ошибка')
	} finally {
		dispatch(
			contractsActions.sendToEmployee({
				data: null,
				loading: false,
				error: null,
			})
		)
	}
}

export const declineEmployee = (payload) => async (dispatch) => {
	try {
		dispatch(
			contractsActions.declineEmployee({
				data: null,
				loading: true,
				error: null,
			})
		)
		const response = await api.contracts.declineEmployee(payload)
		dispatch(
			contractsActions.declineEmployee({
				data: response,
				loading: false,
				error: null,
			})
		)
	} catch (error) {
		const err = error?.response?.data?.message || 'Неизвестная ошибка'
		message.error(err ? err : 'Неизвестная ошибка', 5)
		dispatch(
			contractsActions.declineEmployee({
				data: null,
				loading: false,
				error: err,
			})
		)
	}
}

export const sendForImprovementEmployee = (payload) => async (dispatch) => {
	try {
		dispatch(
			contractsActions.sendForImprovementEmployee({
				data: null,
				loading: true,
				error: null,
			})
		)
		const response = await api.contracts.sendForImprovementEmployee(payload)
		dispatch(
			contractsActions.sendForImprovementEmployee({
				data: response,
				loading: false,
				error: null,
			})
		)
	} catch (error) {
		const err = error?.response?.data?.message || 'Неизвестная ошибка'
		message.error(err ? err : 'Неизвестная ошибка', 5)
		dispatch(
			contractsActions.sendForImprovementEmployee({
				data: null,
				loading: false,
				error: err,
			})
		)
	}
}

export const declineEmployer = (payload) => async (dispatch) => {
	try {
		dispatch(
			contractsActions.declineEmployer({
				data: null,
				loading: true,
				error: null,
			})
		)
		const response = await api.contracts.declineEmployer(payload)
		dispatch(
			contractsActions.declineEmployer({
				data: response,
				loading: false,
				error: null,
			})
		)
	} catch (error) {
		const err = error?.response?.data?.message || 'Неизвестная ошибка'
		dispatch(
			contractsActions.declineEmployer({
				data: null,
				loading: false,
				error: err,
			})
		)
		message.error(err ? err : 'Неизвестная ошибка')
	}
}

export const revertEmployer = (payload, onClose, updateList) => async (dispatch) => {
	try {
		const response = await api.contracts.revertEmployer(payload)
		if (response.success) {
			onClose()
			updateList()
		}
	} catch (error) {
		const err = error?.response?.data?.message || 'Неизвестная ошибка'
		dispatch(
			contractsActions.declineEmployer({
				data: null,
				loading: false,
				error: err,
			})
		)
		message.error(err ? err : 'Неизвестная ошибка')
	}
}

export const deleteEtd = (payload) => async (dispatch) => {
	try {
		dispatch(
			contractsActions.deleteEtd({
				data: null,
				loading: true,
				error: null,
			})
		)
		const response = await api.contracts.deleteEtd(payload)
		dispatch(
			contractsActions.deleteEtd({
				data: response,
				loading: false,
				error: null,
			})
		)
	} catch (error) {
		const err = error?.response?.data?.message || 'Неизвестная ошибка'
		dispatch(
			contractsActions.deleteEtd({
				data: null,
				loading: false,
				error: err,
			})
		)
		message.error(err ? err : 'Неизвестная ошибка')
	}
}

export const saveProject = (payload, contract, isSendToEmployee) => {
	return async (dispatch) => {
		try {
			dispatch(
				contractsActions.saveProject({
					data: null,
					loading: true,
					error: null,
				})
			)
			const data = {
				externalId: contract.externalId,
				valueList: payload,
			}
			const params = {
				isSend: isSendToEmployee,
			}
			const response = await api.contracts.saveProject(data, params)
			dispatch(
				contractsActions.saveProject({
					data: response,
					loading: false,
					error: null,
				})
			)
			dispatch(
				contractsActions.setCurrentEtd({
					data: null,
					loading: false,
					error: null,
				})
			)
			dispatch(
				contractsActions.setCurrentEtdEdit({
					data: null,
					loading: false,
					error: null,
				})
			)
			message.success('Успешно сохранено', 5)
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			dispatch(
				contractsActions.saveProject({
					data: null,
					loading: false,
					error: err,
				})
			)
			message.error(err ? err : 'Неизвестная ошибка')
		}
	}
}

export const getStatusQrSign = (key) => {
	return async (dispatch) => {
		try {
			const response = await api.contracts.mGov.getStatusSign(key)
			dispatch(contractsActions.setStatusSignEtd(response))
			if (response.code === 'sign_success') {
				dispatch(contractsActions.etdToggleSuccess(true))
			}
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err, 5)
			dispatch(contractsActions.etdToggleSuccess(false))
		}
	}
}

export const getImageQr = (externalId, action) => {
	return async (dispatch) => {
		try {
			dispatch(contractsActions.etdToggleLoading(true))
			const { key, qrCode } = await api.contracts.mGov.getQR(externalId, action)
			const image = new Image()
			image.src = `data:image/png;base64,${qrCode}`
			image.onload = () => {
				dispatch(contractsActions.setImageEtd(image.src))
				dispatch(contractsActions.setImageKey(key))
			}
			dispatch(contractsActions.etdToggleLoading(false))
		} catch (error) {
			dispatch(contractsActions.setErrorEtd(error))
			dispatch(contractsActions.etdToggleLoading(false))
		} finally {
			dispatch(contractsActions.etdToggleLoading(false))
		}
	}
}

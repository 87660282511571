import cl from 'classnames'
import styles from './Style.module.scss'
import { useTranslation } from 'react-i18next'
import { useLanguage, useWindowSize } from '@/shared'
import block1 from '@app/images/employer_ico_1.svg'
import block2 from '@app/images/employer_ico_2.svg'
import block3 from '@app/images/employer_ico_3.svg'
import block4 from '@app/images/employer_ico_4.svg'
import block5 from '@app/images/employer_ico_5.svg'
import { StyledSlider } from '@features/home/Styled'
import React from 'react'

const settings = {
	infinite: true,
	swipeToSlide: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	initialSlide: 0,
}

const Worker = () => {
	const { t } = useTranslation()
	const { lang } = useLanguage()
	const { breakpoint } = useWindowSize()

	const array = [
		{
			icon: block1,
			title: t('government_unemployment_support'),
			body: t('termination_employment'),
		},
		{
			icon: block4,
			title: t('control_hr_documents'),
			body: t('managing_documents_security'),
		},
		{
			icon: block1,
			title: t('receive_state_support'),
			body: t('use_opportunity'),
		},
		{
			icon: block2,
			title: t('save_time_collecting_documents'),
			body: t('manage_personal_documents'),
		},
		{
			icon: block5,
			title: t('get_automated_services'),
			body: t('confirm_your_employment'),
		},
	]

	return (
		<section data-section="worker" className={cl(styles.worker, styles.grid)}>
			<div className={cl(styles.container)}>
				{
					breakpoint.md ?
						<div className={cl(styles.grid__grid, styles.grid__line)}>
							<div className={cl(styles.grid__block, styles.grid__block0)}>
								<h2 className={cl('mb-0', styles.grid__title)}>{t('for_worker')}</h2>
								<span className={cl(styles.grid__subtitle)}>{t('based_entered_data_employment')}</span>
							</div>

							{
								array.map((item, index) => (
									<div key={index}
											 className={cl(styles.grid__block, styles[`grid__block${index + 1}`], styles.grid__card)}>
										<img className={'mb-3'} src={item.icon} alt={item.title} width={42} height={42} />
										<p>{item.title}</p>
										<span>{item.body}</span>
									</div>
								))
							}
						</div>
						:
						<div className={cl('mb-5', styles.grid__line)}>
							<div>
								<h2 className={cl('mb-0', styles.grid__title)}>{t('for_worker')}</h2>
								<span className={cl(styles.grid__subtitle)}>{t('based_entered_data_employment')}</span>
							</div>

							<div>
								<StyledSlider {...settings}>
									{array.map((item, index) => {
										return (
											<div className="p-4" key={index}>
												<div key={index}
														 className={cl(styles.grid__block, styles[`grid__block${index + 1}`], styles.grid__card)}>
													<img className={'mb-3'} src={item.icon} alt={item.title} width={42} height={42} />
													<p>{item.title}</p>
													<span>{item.body}</span>
												</div>
											</div>
										)
									})}
								</StyledSlider>
							</div>
						</div>
				}
			</div>
		</section>
	)
}

export default Worker
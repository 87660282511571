import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { memo } from 'react'
import cl from 'classnames'
import styles from './Accordion.module.scss'

export const Accordion = memo(({
																 title = '',
																 description = '',
																 isExpanded,
																 handleExpanded,
															 }) => {

	return (
		<div className={cl(styles.accordionItem, {
			[cl(styles.accordionItem__isExpanded)]: isExpanded,
		})}>
			<div className={cl(styles.accordionHeader, {
				[cl(styles.accordionHeader__isExpanded)]: isExpanded,
			})} onClick={handleExpanded}>
				<div className={cl(styles.accordionHeaderInner, {
					[cl(styles.accordionHeaderInner__isExpanded)]: isExpanded,
				})}>
					{title}
				</div>

				<ExpandMoreIcon className={cl(styles.accordionIcon, {
					[cl(styles.accordionIcon__isExpanded)]: isExpanded,
				})}/>
			</div>

			<div className={cl(styles.accordionContent, {
				[cl(styles.accordionContent__isExpanded)]: isExpanded,
			})}
			>
				<div className={cl(styles.accordionContentInner, {
					[cl(styles.accordionContentInner__isExpanded)]: isExpanded,
				})}>
					{description}
				</div>
			</div>
		</div>
	)
})
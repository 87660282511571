import React, { lazy, Suspense, useState } from 'react'
import { LoadingBlocker } from '@/components/Loader'
import styles from './Home.module.scss'
import containerStyles from '@/features/home/Style.module.scss'
import { LANGUAGE_ENUM, useWindowSize } from '@/shared'
import cl from 'classnames'
import { Close8 } from '@app/images'
import icon from '@/assets/home/banner-ico.svg'
import { useTranslation } from 'react-i18next'
import { getOs, OS } from '@pages/AppDownload/AppDownload'
import Button from '@/components/Button'

const Header = lazy(() => import('@/features/home/Header.jsx'))
const HeaderMobile = lazy(() => import('@/features/home/HeaderMobile'))
const Form = lazy(() => import('@/features/home/Form'))
const Info = lazy(() => import('@/features/home/Info'))
const Employer = lazy(() => import('@/features/home/Employer'))
const Worker = lazy(() => import('@/features/home/Worker'))
const Hero = lazy(() => import('@/features/home/Hero'))
const Footer = lazy(() => import('@/features/home/Footer'))

export const Home = () => {
	const { breakpoint } = useWindowSize()
	const { t } = useTranslation()

	const [isAppVisible, setIsAppVisible] = useState(true)

	const google_play = process.env.REACT_APP_MOBILE_BANNER_GOOGLE
	const app_store = process.env.REACT_APP_MOBILE_BANNER_APPLE

	const os = getOs()

	const link = {
		[OS.ios]: app_store,
		[OS.android]: google_play,
		undefined: google_play,
	}

	const typeText = {
		[OS.ios]: t('available_google', {type: 'AppDownload Store'}),
		[OS.android]: t('available', {type: 'Google Play'}),
		undefined: t('available', {type: 'Google Play'}),
	}


	return (
		<Suspense fallback={<LoadingBlocker />}>
			{
				!breakpoint.lg && (
					isAppVisible &&
					<div className={cl(styles.app)} id='app'>
						<div className={cl(containerStyles.container)}>
							<div
								className={styles.app__close}
								onClick={() => {
									setIsAppVisible(false)
								}}
							>
								<img src={Close8} alt="close_icon" width={16} height={16} />
							</div>

							<div className={cl(styles.app__main)}>
								<img src={icon} width={30} height={30} className={cl(styles.app__logo)} />
								<div>
							<span className={cl(styles.app__title)}>
							{t('portal_employment_contracts')}
						</span>

									<span className={cl(styles.app__subtitle)}>
									{typeText[os]}
								</span>
								</div>
							</div>

							<a href={link[os]} target='_blank'>
								<Button>
									{t('notification_open')}
								</Button>
							</a>
						</div>
					</div>
				)
			}

			<div className={styles.wrapper} id="main">
				{breakpoint.lg ? <Header /> : <HeaderMobile />}
				<main className={styles.main}>
					<Hero />
					<Info />
					<Employer />
					<Worker />
					<Form />
				</main>
				<Footer />
			</div>
		</Suspense>
	)
}
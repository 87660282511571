import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { userActions, userSelectors } from '@/entity/user'
import { Title, Button } from '@/ui'
import styles from './UserProfile.module.scss'
import { UploadImagePlaceholder } from '@app/images/index'
import { EmployeeAccountInfo, EmployeeContactInfo, ExperienceInfo } from './info-table'
import { Deletion } from '@features/userProfile/deletion/Deletion'
import { LoadingBlocker, LoadingScreen } from '@/components/Loader'
import { Tooltip } from 'antd'
import { Info } from '@app/icons'
import { oppvSelector, oppvThunks } from '@/entity/oppv'
import { Tabs } from '@/features'
import { Verification } from '@features/userProfile/page/Verification'
import { VerificationSms } from '@features/userProfile/page/VerificationSms'
import { VerificationQR } from '@features/userProfile/page/VerificationQR'
import Radio from '@/components/Radio/Radio'
import { setToLocalStorage, useToggle } from '@/shared'
import { AddEmployerFl } from '@features/employerProfile/addEmployerFl'
import { useHistory } from 'react-router-dom'

export const UserProfile = () => {
	const { t } = useTranslation()
	const tabControlRef = useRef(null)
	const dispatch = useDispatch()
	const isLoading = useSelector(userSelectors.isLoading)
	const user = useSelector(userSelectors.user)
	const isLoadingOppv = useSelector(oppvSelector.isLoading)
	const isSuccessOppv = useSelector(oppvSelector.isSuccess)
	const oppv = useSelector(oppvSelector.oppv)
	const history = useHistory()
	const [actionModalIsOpen, setActionModalIsOpen] = useState(false)
	const [paramRequestEnpf, setParamRequestEnpf] = useState([])
	const [isOpenedAddFlEmployer, toggleOpenAddFlEmployer] = useToggle(false)
	const currentFlEmployer = useSelector(userSelectors.currentFlEmployer)
	const avatarPath = useMemo(
		() => (user?.photoId ? `${process.env.REACT_APP_API_URL}/file/notype/${user.photoId}` : UploadImagePlaceholder),
		[user]
	)
	const hasProfile = useMemo(() => user?.iin, [user])
	const tabs = ['ecp_data', 'sms_data', 'qr_data']
	const data = {
		ecp_data: (
			<div className={styles.wrapper}>
				<Verification user={user} />
			</div>
		),
		sms_data: <VerificationSms />,
		qr_data: <VerificationQR />,
	}
	const updateParamRequestEnpf = (name, value) => {
		setParamRequestEnpf((prevSelectedValues) => ({
			...prevSelectedValues,
			[name]: value,
		}))
	}

	const handleChange = (e) => {
		const { name, value } = e.target
		updateParamRequestEnpf(name, value)
	}

	const getSum = useCallback(() => dispatch(oppvThunks.getOppvLast()), [])
	const updateOppv = useCallback(
		() => dispatch(oppvThunks.sendOppv({ xtypeENPF: paramRequestEnpf.enpf })),
		[paramRequestEnpf]
	)

	useEffect(() => {
		if (hasProfile && !isLoadingOppv) {
			getSum()
		}
	}, [hasProfile, oppv])

	const onClickOpenModalFlEmployer = () => {
		toggleOpenAddFlEmployer(true)
	}

	const onCloseSuccess = () => {
		toggleOpenAddFlEmployer(false)
		onRedirect()
	}

	const onRedirect = () => {
		const role = 'ROLE_EMPLOYER'
		setToLocalStorage('activeRole', role)
		dispatch(userActions.setActiveRole(role))
		history.push('/employer')
	}

	return (
		<>
			{isLoading ? <LoadingBlocker /> : null}
			{actionModalIsOpen && hasProfile && <Deletion onClose={() => setActionModalIsOpen(false)} />}
			<div className={styles.container}>
				<div className={`flex-1`}>
					<div className={styles.hideMobile}>
						{hasProfile ? (
							<>
								{' '}
								<div className={styles.titleStyle}>
									<Title className="flex-1 mb-24">{t('verification')}</Title>
								</div>
								<div className={styles.actions}>
									<Button onClick={() => setActionModalIsOpen(true)}>{t('delete_sign')}</Button>
									{isOpenedAddFlEmployer ? (
										<AddEmployerFl
											onClose={() => toggleOpenAddFlEmployer(false)}
											onCloseSuccess={onCloseSuccess}
										></AddEmployerFl>
									) : null}
									<Button
										buttonClassName={styles.actionsWrapper}
										onClick={onClickOpenModalFlEmployer}
										disabled={currentFlEmployer}
									>
										{t('add_fl_employer')}
									</Button>
								</div>
							</>
						) : (
							<>
								{' '}
								<div className={styles.titleStyle}>
									<Title className="flex-1 mb-24">{t('verification_type')}</Title>
								</div>
								<Tabs tabs={tabs} data={data} ref={tabControlRef} />
							</>
						)}
					</div>
					<div className={styles.mobile}>
						<div className={styles.titleStyle}>
							<Title>{t('verification')}</Title>
						</div>

						{!hasProfile ? (
							<div className={styles.wrapper}>
								<VerificationSms />
							</div>
						) : (
							<Button onClick={() => setActionModalIsOpen(true)}>{t('delete_sign')}</Button>
						)}
					</div>
					{hasProfile ? (
						<div className={styles.information}>
							<EmployeeAccountInfo worker={user} />
							<EmployeeContactInfo worker={user} />
							<div>
								<div className={styles.flexTitle}>
									<Title>
										{t('experience_working_title')}{' '}
										<Tooltip
											placement="rightTop"
											color="white"
											overlayInnerStyle={{ color: '#5B6880', width: '320px' }}
											title={t('oppv_hint1')}
										>
											<span>
												<Info className={styles.hintIcon} />
											</span>
										</Tooltip>
									</Title>
									{isSuccessOppv && oppv !== '' ? (
										<div className={styles.updateData}>
											{t('oppv_last_update')}
											{oppv.sendDate}
										</div>
									) : null}
								</div>
								<div className={'flex-1'}>
									<div className={styles.radioStyle}>
										<Radio form={paramRequestEnpf} name={'enpf'} label={t('enpf')} id="0" handleChange={handleChange} />
										<Radio
											form={paramRequestEnpf}
											name={'enpf'}
											label={t('enpf_oppv')}
											id="1"
											handleChange={handleChange}
										/>
										<Radio
											form={paramRequestEnpf}
											name={'enpf'}
											label={t('enpf_oppv_opv')}
											id="2"
											handleChange={handleChange}
										/>
									</div>
									{hasProfile && oppv === '' ? (
										<Button buttonClassName={styles.buttonWith} onClick={() => updateOppv()}>
											{t('oppv_calculate')}
										</Button>
									) : (
										<Button
											disabled={paramRequestEnpf.length === 0}
											buttonClassName={styles.buttonWithMedium}
											onClick={() => updateOppv()}
										>
											{t('update_data')}
										</Button>
									)}
									{isLoadingOppv ? (
										<div className={styles.loader}>
											<LoadingScreen />
										</div>
									) : (
										!isLoadingOppv && isSuccessOppv && <ExperienceInfo oppv={oppv} />
									)}
								</div>
							</div>
						</div>
					) : null}
				</div>
				{hasProfile ? (
					<div className="flex-1">
						<p className={styles.label}>{t('photo')}:</p>
						<img src={avatarPath} alt="avatar" className={styles.logoImage} />
					</div>
				) : null}
			</div>
		</>
	)
}

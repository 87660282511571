import cl from 'classnames'
import styles from './Style.module.scss'
import { useTranslation } from 'react-i18next'
import { BREAKPOINTS, LANGUAGE_ENUM, useLanguage, useWindowSize } from '@/shared'
import dayjs from 'dayjs'
import LogoLang from '@/assets/home/lang.svg'
import React, { useMemo, useState } from 'react'
import useEnbekEcoSystemLink from '@/shared/lib/hooks/useEnbekEcoSystemLink'
import { useDispatch, useSelector } from 'react-redux'
import { Logo as LogoImage } from '@app/images'
import { Link } from 'react-router-dom'
import logoInstagram from '@/assets/home/instagram.svg'
import qrCode from '@app/images/qr-code.webp'
import { message, Modal } from 'antd'
import { Field as FormikField, FieldArray, Form, Formik, useFormik, useFormikContext } from 'formik'
import { Button, Error, Field, Select } from '@/ui'
import NumberFormat from 'react-number-format'
import paper from '@/assets/home/PaperClip.svg'
import Captcha from '@/components/Captcha/Captcha'
import { object, string } from 'yup'
import { EMAIL_REGEX, IIN_REGEX, PHONE_REGEX } from '@/constants/regex'
import { isEmpty } from 'lodash'
import { useDropzone } from 'react-dropzone'
import doc from '@/assets/home/doc.svg'
import trash from '@/assets/home/trash.svg'
import { mainActions, mainThunks } from '@app/store/main'
import { LoadingBlocker } from '@/components/Loader'

const currentLangPrefix = {
	[LANGUAGE_ENUM.rus]: 'ru',
	[LANGUAGE_ENUM.kz]: 'kz',
}

const FEEDBACK_FORM_NAME = {
	topic: 'topic',
	data: 'data',
	phone: 'phone',
	name: 'name',
	mail: 'mail',
	kato: 'kato',
	iin: 'iin',
	fileUpload: 'fileUpload',
}

const feedbackInitialState = {

}

const Footer = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { lang, handleChangeLanguage } = useLanguage()
	const { refEnbek, refSkills, refBusiness, refCareer, refMigration, refMansap } = useEnbekEcoSystemLink(lang)
	const { breakpoint } = useWindowSize()
	const { getInputProps } = useDropzone({ noClick: true })

	const FormValidationSchema = object().shape({
		[FEEDBACK_FORM_NAME.topic]: string().required(t('required')),
		[FEEDBACK_FORM_NAME.data]: string().required(t('required')),
		[FEEDBACK_FORM_NAME.phone]: string().required(t('required')).matches(PHONE_REGEX, t('invalid_phone_number_entered')),
		[FEEDBACK_FORM_NAME.name]: string().required(t('required')),
		[FEEDBACK_FORM_NAME.mail]: string().required(t('required')).matches(EMAIL_REGEX, t('invalid_mail_entered')),
		[FEEDBACK_FORM_NAME.kato]: string().required(t('required')),
		[FEEDBACK_FORM_NAME.iin]: string().required(t('required')).matches(IIN_REGEX, t('not_correct_iin')),
	})

	const [isFeedbackModalVisible, setFeedbackModalVisible] = useState(false)
	const [captchaData, setCaptchaData] = useState(null)

	const { user, activeRole, companies, districts, feedback } = useSelector((state) => ({
		user: state.user.user,
		activeRole: state.user.activeRole,
		companies: state.user.employerCompanies,
		districts: state.handbook.districts,
		feedback: state.main.feedback,
	}))

	const condition = useMemo(() => {
		let res = (activeRole === 'ROLE_EMPLOYER' && !companies.length) || (activeRole === 'ROLE_EMPLOYEE' && !user?.iin)
		return res ? '/reference-information/3' : '/contracts'
	}, [activeRole, companies.length, user])

	const urls = useMemo(() => {
		let terms = {
			[LANGUAGE_ENUM.rus]: process.env.REACT_APP_REDIRECT_ENBEK + process.env.REACT_APP_REDIRECT_TERMS_RU,
			[LANGUAGE_ENUM.kz]: process.env.REACT_APP_REDIRECT_ENBEK + process.env.REACT_APP_REDIRECT_TERMS_KZ,
		}
		return {
			auth: process.env.REACT_APP_LOGIN + currentLangPrefix[lang] + process.env.REACT_APP_REDIRECT_ROLE,
			terms: terms[lang],
		}
	}, [lang])

	const mainItem = {
		title: t('main'),
		array: [
			{
				link: !user ? urls.auth : condition,
				label: !user ? t('employment_contract') : t('employment_contract'),
				isExternal: true,
			},
			{
				link: '/forms',
				label: t('online_consultant'),
			},
			{
				link: '/reference-information/2',
				label: t('services'),
			},
			{
				link: '/reference-information/3',
				label: t('questions'),
			},
		],
	}

	const portalItem = {
		title: t('portal'),
		array: [
			{
				link: '/about-project',
				label: t('about_project'),
			},
			{
				link: '/for-employers',
				label: t('for_employer'),
			},
			{
				link: '/for-workers',
				label: t('for_worker'),
			},
			{
				link: urls.terms,
				label: t('terms_use'),
				isExternal: true,
			},
			{
				link: '/reference-information/1',
				label: t('helpTab'),
			},
		],
	}

	const ecosystemItem = {
		title: t('digital_ecosystem_employment'),
		array: [
			{
				link: refEnbek,
				label: t('electronic_labor_exchange'),
			},
			{
				link: refSkills,
				label: 'Skills.Enbek',
			},
			{
				link: refBusiness,
				label: 'Business.Enbek',
			},
			{
				link: refCareer,
				label: 'Career.Enbek',
			},
			{
				link: process.env.REACT_APP_REDIRECT_MIGRATION,
				label: 'Migration.Enbek',
			},
			{
				link: process.env.REACT_APP_REDIRECT_MANSAP,
				label: 'Mansap.Enbek',
			},
		],
	}

	const handleLangClick = (e) => {
		e.preventDefault()

		const currentLanguage = {
			[LANGUAGE_ENUM.kz]: LANGUAGE_ENUM.rus,
			[LANGUAGE_ENUM.rus]: LANGUAGE_ENUM.kz,
		}

		handleChangeLanguage(currentLanguage[lang])
	}

	const langLabel = {
		[LANGUAGE_ENUM.kz]: t('rus'),
		[LANGUAGE_ENUM.rus]: t('rus'),
	}

	const handleFeedbackModalOpen = () => {
		setFeedbackModalVisible(prevState => !prevState)
	}

	const handleSubmitFeedback = (data, formikHelpers) => {
		let formData = new FormData()

		formData.set(FEEDBACK_FORM_NAME.topic, data.topic)
		formData.set(FEEDBACK_FORM_NAME.data, data.data)
		formData.set(FEEDBACK_FORM_NAME.phone, data.phone)
		formData.set(FEEDBACK_FORM_NAME.name, data.name)
		formData.set(FEEDBACK_FORM_NAME.mail, data.mail)
		formData.set(FEEDBACK_FORM_NAME.kato, data.kato)
		formData.set(FEEDBACK_FORM_NAME.iin, data.iin)

		if(data[FEEDBACK_FORM_NAME.fileUpload]){
			for (let i = 0; i < data[FEEDBACK_FORM_NAME.fileUpload]?.length; i++) {
				formData.append(`fileUpload`, data[FEEDBACK_FORM_NAME.fileUpload][i])
			}
		}

		dispatch(mainThunks.setFeedback({
				data: {form: formData, captcha: captchaData},
				onHandler: () => {
					handleFeedbackModalOpen()
					formikHelpers.resetForm()
					setCaptchaData(null)
				},
			}),
		)
	}

	return (
		<>
			<footer className={cl(styles.footer)}>
				<div className={cl(styles.container, styles.footer__wrapper)}>
					<div className={cl(styles.footer__difficult)}>
						<div>
							<p className={'mb-2'}>{t('difficulties')}</p>
							<span>{t('contact_technical_support')}</span>
						</div>

						<Button onClick={handleFeedbackModalOpen}>{t('send_message')}</Button>
					</div>

					<div className={cl(styles.footer__content)}>
						<div className={cl(styles.footer__contentItem)}>
							<div className={cl(styles.footer__logo)}>
								<Link to={'/'} className={'mb-3'}>
									<img src={LogoImage} alt="enbek" width={114} height={40} />
								</Link>
								<span className={cl(styles.footer__text, 'w-75', 'mb-2')}>{t('digital_ecosystem')}</span>
								<a href={process.env.REACT_APP_INSTAGRAM_LINK} target="_blank" rel="noopener noreferrer">
									<img src={logoInstagram} alt="instagram"></img>
								</a>
							</div>

							<div className={cl('mt-lg-5', 'mt-md-4', 'mt-sm-2')}>
								<span className={cl(styles.footer__numberPhone)}>1414</span>
								<p className={cl(styles.footer__text, 'mb-0')}>{t('conversations_kz')}</p>
								<p className={cl(styles.footer__text, 'mb-0')}>{t('voice_robot')}</p>
							</div>
						</div>

						<div className={cl(styles.footer__contentItem)}>
							<div>
							<span className={cl(styles.footer__title)}>
							{mainItem.title}
						</span>

								<ul className={cl('p-0', 'm-0', 'mt-3', styles.footer__list)}>
									{mainItem.array.map((item, index) => (
										<li key={index}>
											{item.isExternal ?
												<a href={item.link} className={cl(styles.footer__text)}>
													{item.label}
												</a> :
												<Link to={item.link} className={cl(styles.footer__text)}>
													{item.label}
												</Link>
											}
										</li>
									))}
								</ul>
							</div>
						</div>

						<div className={cl(styles.footer__contentItem)}>
							<div>
								<span className={cl(styles.footer__title)}>
									{portalItem.title}
								</span>

								<ul className={cl('p-0', 'm-0', 'mt-3', styles.footer__list)}>
									{portalItem.array.map((item, index) => (
										<li key={index}>
											{item.isExternal ?
												<a href={item.link} className={cl(styles.footer__text)}>
													{item.label}
												</a> :
												<Link to={item.link} className={cl(styles.footer__text)}>
													{item.label}
												</Link>
											}
										</li>
									))}
								</ul>
							</div>
						</div>

						<div className={cl(styles.footer__contentItem)}>
							<div>
							<span className={cl(styles.footer__title)}>
								{ecosystemItem.title}
							</span>

								<ul className={cl('p-0', 'm-0', 'mt-3', styles.footer__list)}>
									{ecosystemItem.array.map((item, index) => (
										<li key={index}>
											<a href={item.link} className={cl(styles.footer__text)}>
												{item.label}
											</a>
										</li>
									))}
								</ul>
							</div>

							<div className={cl('mt-4', styles.footer__qr)}>
								<img src={qrCode} alt="qr-code" width={120} height={120} />

								<span className={cl(styles.footer__text)}>
								{t('app_download_link')}
							</span>
							</div>
						</div>
					</div>
				</div>

				<div className={styles.footer__br} />

				<div className={cl(styles.container, styles.footer__copyright)}>
					<span>&copy; 2020 - {dayjs().year()}, {t('crtr')}</span>

					<a onClick={handleLangClick} className={cl('ms-5')}>
						<span>{langLabel[lang]}</span>
						<img src={LogoLang} alt={'globe'} className={'ms-2'} />
					</a>
				</div>
			</footer>

			<Modal
				centered
				footer={null}
				open={isFeedbackModalVisible}
				closable={true}
				onCancel={handleFeedbackModalOpen}
			>
				<div
					className={styles.feedback}
				>
					<p className={cl(styles.feedback__title, 'mb-2')}>{t('feedback')}</p>
					<p className={styles.feedback__subtitle}>{t('ask_feedback')}</p>
					<Formik
						initialValues={feedbackInitialState}
						onSubmit={(values, formikHelpers) => handleSubmitFeedback(values, formikHelpers)}
						validationSchema={FormValidationSchema}
					>
						{({ values, errors, setFieldValue }) => {
							return (
								<Form className={styles.feedback__form}>
									<FormikField name={FEEDBACK_FORM_NAME.name}>
										{({ field, meta: { touched, error } }) => (
											<>
												<input
													placeholder={t('your_name')}
													className={styles.feedback__input}
													{...field}
												/>

												{(touched && error) && <Error>{error}</Error>}
											</>
										)}
									</FormikField>

									<FormikField name={FEEDBACK_FORM_NAME.phone}>
										{({ field, meta: { touched, error } }) => (
											<>
												<NumberFormat
													format="+# (###) ### - ## - ##"
													mask=""
													placeholder={t('your_phone_number')}
													className={styles.feedback__input}
													{...field}
												/>
												{(touched && error) && <Error>{error}</Error>}
											</>
										)}
									</FormikField>

									<FormikField name={FEEDBACK_FORM_NAME.mail}>
										{({ field, meta: { touched, error } }) => (
											<>
												<input
													placeholder={t('your_mail')}
													className={styles.feedback__input}
													{...field}
												/>
												{(touched && error) && <Error>{error}</Error>}
											</>
										)}
									</FormikField>

									<FormikField name={FEEDBACK_FORM_NAME.iin}>
										{({ field, meta: { touched, error } }) => (
											<>
												<input
													placeholder={t('enter_iin')}
													type='number'
													className={styles.feedback__input}
													{...field}
												/>
												{(touched && error) && <Error>{error}</Error>}
											</>
										)}
									</FormikField>

									<FormikField name={FEEDBACK_FORM_NAME.kato}>
										{({ field, meta: { touched, error } }) => {
											return (
												<>
													<Select
														placeholder="Выберите регион из справочника"
														options={districts || []}
														className={styles.feedback__select}
														{...field}
													/>
													{(touched && error) && <Error>{error}</Error>}
												</>
											)
										}}
									</FormikField>

									<FormikField name={FEEDBACK_FORM_NAME.topic}>
										{({ field, meta: { touched, error } }) => (
											<>
												<input
													placeholder={t('question_topic')}
													className={styles.feedback__input}
													{...field}
												/>
												{(touched && error) && <Error>{error}</Error>}
											</>
										)}
									</FormikField>

									<FormikField name={FEEDBACK_FORM_NAME.data}>
										{({ field, meta: { touched, error } }) => (
											<>
												<textarea
													placeholder={t('question')}
													className={styles.feedback__input}
													{...field}
												/>
												{(touched && error) && <Error>{error}</Error>}
											</>
										)}
									</FormikField>

									<div>
										<Captcha recaptcha={(value) => setCaptchaData(value)} size={breakpoint[BREAKPOINTS.SM] ? 'normal' : 'compact'} />
									</div>

									<ul className={styles.feedback__list}>
										<FieldArray name={FEEDBACK_FORM_NAME.fileUpload}>
											{({form, remove, push}) => {
												return (
													<>
														{form.values[FEEDBACK_FORM_NAME.fileUpload]?.map((item, index) => (
															<li key={index} className={styles.feedback__listItem}>
																<div>
																	<img src={doc} />
																	<span>{item.name}</span>
																</div>
																<div onClick={() => remove(index)}>
																	<img src={trash} />
																	<span>{t('remove')}</span>
																</div>
															</li>
														))}
													</>
												)
											}}
										</FieldArray>
									</ul>

									<div className={styles.feedback__footer}>
										<label className={styles.feedback__attache_btn}>
											<a>
												<img src={paper} />
												<span>{t('attache_file')}</span>
												<input type="file" {...getInputProps()} onChange={(e) => {
													setFieldValue(FEEDBACK_FORM_NAME.fileUpload, [...(values[FEEDBACK_FORM_NAME.fileUpload] || []), ...e.target.files])
												}} />
											</a>
										</label>

										<Button type="submit" disabled={!(isEmpty(errors) && !!captchaData)}>
											<span>{t('send')}</span>
										</Button>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</Modal>

			{feedback.loading && <LoadingBlocker />}
		</>
	)
}

export default Footer
import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import { InfoDevelopment } from '@/components/infoDevelopment'
import React, { useCallback, useEffect } from 'react'
import styles from './MonitoringViolations.module.scss'
import { Typography } from '@pages/CompanyPassport/Typography/Typography'
import { Table, TableColumn } from '@/components/Table'
import { HrPagination } from '@/components/Pagination'
import { safetyService } from '@/shared/api/rest/safety/safetyService'
import { useFetchData } from '@pages/CompanyPassport/hooks/useFetchData'
import { violationsDefault } from '@pages/CompanyPassport/dfault'
import { LoadingBlocker } from '@/components/Loader'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'

const Indicator = ({ title, value }) => {
	return (
		<div className={styles.indicatorItemContainer}>
			<Typography variant={'title_24_400'}>{value}</Typography>
			<Typography variant={'title_14_400_gray'}>{title}</Typography>
		</div>
	)
}

export const MonitoringViolations = () => {
	const lang = getCurrentLanguage()
	const langPrefix = lang === 'rus' ? 'Ru' : 'Kz'

	const { t } = useTranslation()
	const [currentPage, setCurrentPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const [totalPages, setTotalPages] = React.useState(0)

	const fetchFunction = useCallback(
		async (reqestedBin) => {
			return safetyService.getViolationMonitoring(currentPage, rowsPerPage, reqestedBin)
		},
		[currentPage, rowsPerPage]
	)

	const { data, isEmpty, loading } = useFetchData({
		bin: activeCompany.bin,
		fetchFunction,
		defaultValue: violationsDefault,
		defaultChecks: true,
	})

	useEffect(() => {
		if (data?.totalViolations) {
			setTotalPages(Math.ceil(data.totalViolations / rowsPerPage))
		}
	}, [data])

	if (loading) {
		return (
			<RedirectUnAuthUser>
				<ProfileTemplate title={t('ckp_violations_monitoring')}>
					<LoadingBlocker />
				</ProfileTemplate>
			</RedirectUnAuthUser>
		)
	}

	if (isEmpty || data?.violations == null) {
		return (
			<RedirectUnAuthUser>
				<ProfileTemplate title={t('ckp_violations_monitoring')}>
					<InfoDevelopment title={t('ckp_no_info_on_your_enterprise')} small={false} />
				</ProfileTemplate>
			</RedirectUnAuthUser>
		)
	}

	return (
		<RedirectUnAuthUser>
			<ProfileTemplate title={t('ckp_violations_monitoring')}>
				<div className={styles.violationsContainer}>
					<div className={styles.indicatorContainer}>
						<InfoDevelopment title={'ckp_info_on_violations_updated_monthly'} small={true} />
						<div className={styles.indicatorTable}>
							<Indicator title={t('ckp_employment_contracts')} value={data?.totalEmploymentContacts} />
							<Indicator title={t('ckp_total_OPV')} value={data?.totalOPV} />
							<Indicator title={t('ckp_discrepancy_OPV_TD')} value={data?.divergenceOPVandTd} />
							<Indicator title={t('ckp_discrepancy_TD_OPV')} value={data?.divergenceTdAndOPV} />
							<Indicator title={t('ckp_IRS_permissions')} value={data?.allowedIrs} />
							<Indicator title={t('ckp_labor_migrants')} value={data?.laborMigrants} />
						</div>
					</div>
					<div className={styles.listContainer}>
						<Table tableName="social-leaves" data={data?.violations} emptyPlaceholder="-">
							<TableColumn dataKey="dateOfDetection" heading={t('ckp_detection_date')} />
							<TableColumn dataKey={`violationType${langPrefix}`} heading={t('ckp_violation_type')} />
							<TableColumn dataKey={`description${langPrefix}`} heading={t('ckp_description')} />
							<TableColumn dataKey="workersCount" heading={t('ckp_workers')} />
							<TableColumn dataKey={`source${langPrefix}`} heading={t('ckp_source')} />
						</Table>

						<HrPagination
							id="hr-pagination"
							rowsPerPageitems={[
								{ size: 10, label: '10' },
								{ size: 50, label: '50' },
								{ size: 100, label: '100' },
							]}
							rowsPerPage={rowsPerPage}
							numberOfElements={data?.violations?.length || 0}
							totalElements={data?.totalViolations || 0}
							totalPages={totalPages}
							handleChangePage={(_, page) => {
								setCurrentPage(page - 1)
							}}
							handleChangeRowsPerPage={(rows) => {
								setRowsPerPage(rows)
								setCurrentPage(0)
							}}
							page={currentPage}
						/>
					</div>
				</div>
			</ProfileTemplate>
		</RedirectUnAuthUser>
	)
}

import React from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { Title } from '@Root/components/Typography'
import Header from '@/components/Header'
import PreHeader from '@/components/home/components/PreHeader'
import Footer from '@features/home/Footer'
export const AboutProjectPage = () => {
	const { t } = useTranslation()
	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<Header active="about_project" dark />
			<div className={`${styles.page} container`}>
				<Title level="1">{t('about_project')}</Title>
				<div className={styles.main_content}>
					<p>
						<b>{t('pilot_launched')}</b>
						{t('implementation_unified_accounting')}
						<b>{t('for_the_implementation')}</b>
						{t('implementation_unified_accounting_2')}
					</p>
				</div>
				<div className={styles.main_content}>
					<p>
						{t('also_from_november')}
						<b>{t('portal_launched_service')}</b>
						{t('organizations_not_have_information')}
						<b>{t('organizations_not_have_information_2')}</b>
					</p>
				</div>
				<div className={styles.main_content}>
					<p>{t('general_repository')}</p>
				</div>
				<div className={styles.content_list}>
					<p>
						{t('on_may_amendments')}
						<b>{t('must')}</b>
						{t('enter_information')}
						<b>{t('esutd')}</b>
					</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('by_order_minister')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('by_order_minister_november')}</p>
				</div>
				<div className={styles.main_content}>
					<p>
						<b>{t('portal_labor_resources')}</b>
						{t('is_designed_automate')}
					</p>
				</div>
				<div className={styles.main_content}>
					<p>
						<b>{t('at_moment_portal')}</b>
					</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('send_information_about_employment')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('view_all_employment')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('pass_self_assessment')}</p>
				</div>
				<div className={styles.main_content}>
					<p>
						<b>{t('portal_employees__opportunity')}</b>
					</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('view_information_about_employment')}</p>
				</div>
				<div className={styles.content_list}>
					<p>{t('download_certificate_from_place_work')}</p>
				</div>
				<div className={styles.main_content}>
					<p>{t('in_future_functionality')}</p>
				</div>
			</div>
			<Footer />
		</>
	)
}

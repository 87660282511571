import cl from 'classnames'
import styles from './Style.module.scss'
import { useTranslation } from 'react-i18next'
import { Select } from '@/ui'
import { useDispatch, useSelector } from 'react-redux'
import { handbookSelectors, handbookThunks } from '@/entity/handbook'
import { useEffect, useMemo, useState } from 'react'
import { mainSelectors, mainThunks } from '@app/store/main'
import { useLanguage } from '@/shared'
import phoneCallIcon from '@/assets/home/phone-call.svg'
import mailIcon from '@/assets/home/mail.svg'

const Form = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { lang } = useLanguage()


	const [currentRegion, setCurrentRegion] = useState(null)

	useEffect(() => {
		dispatch(handbookThunks.getDistricts())
	}, [])

	const { districts, regionalPowerContact } = useSelector((state) => ({
		districts: state.handbook.districts,
		regionalPowerContact: state.main.regionalPowerContact
	}))

	const districtsArray = useMemo(() => {
		return districts?.map(item => ({
			code: item.code.substring(0, 2),
			kz: item.kz,
			rus: item.rus,
		}))
	}, [districts])


	const onChange = (value) => {
		setCurrentRegion(value)
		dispatch(mainThunks.searchRegionalPower({country: value}))
	}

	const iconTypes = {
		phone: phoneCallIcon,
		email: mailIcon,
	}

	return (
		<section data-section="form" className={cl(styles.form)}>
			<div className={cl(styles.container, styles.form__wrapper)}>
				<div className={cl(styles.form__form)}>
					<h4 className={cl('mb-0')}>{t('refer_to_regional_inspection')}</h4>

					<Select
						options={districtsArray || []}
						onChange={onChange}
						placeholder={t('chose_area')}
						value={currentRegion}
					/>
				</div>


				{
					regionalPowerContact &&
					<div className={'mt-4'}>
						<p className={cl(styles.form__footerTitle)}>
							{regionalPowerContact?.name?.[lang]}
						</p>

						<div className={cl(styles.form__footerWrapper)}>
							<div>
								<div className={cl(styles.form__footerContent, 'mb-2')}>
									<span className={cl(styles.form__footerTitle, 'mb-2')}>{t('address')}:</span>
									<span className={cl(styles.form__footerText)}>{regionalPowerContact?.address?.[lang]}</span>
								</div>
								<div className={cl(styles.form__footerContactWrapper)}>
									{['phone', 'mail'].map((type) => (
										<div key={type} className={cl(styles.form__footerPhone)}>
											<img src={iconTypes[type]} alt={regionalPowerContact?.[type]?.[lang]} />
											<span className={cl(styles.form__footerText)}>{regionalPowerContact?.[type]?.[lang]}</span>
										</div>
									))}
								</div>
							</div>

							<div>
								<div className={cl(styles.form__footerContent, 'mb-2')}>
									<span className={cl(styles.form__footerTitle, 'mb-2')}>{t('inspector')}:</span>
									{regionalPowerContact?.inspectors[0]?.map((arr, arrIdx) => {
										return (
											<span key={arrIdx} className={cl(styles.form__footerText)}>{lang === 'rus' ? arr.nameRu : arr.nameKz}</span>
										)
									})}
								</div>

								<div className={cl(styles.form__footerContent)}>
									<span className={cl(styles.form__footerTitle, 'mb-2')}>{t('director')}:</span>
									<span className={cl(styles.form__footerText)}>{regionalPowerContact?.director?.[lang]}</span>
								</div>
							</div>
						</div>
					</div>
				}

			</div>
		</section>
	)
}

export default Form
import React, { useEffect, useMemo } from 'react'
import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import { styled } from '@mui/system'
import TabsUnstyled from '@mui/base/Tabs'
import TabsListUnstyled from '@/ui/atoms/tabsList'
import Tab from '@/ui/atoms/tab'
import TabPanelUnstyled from '@mui/base/TabPanel'
import { useTranslation } from 'react-i18next'
import Staffing from '@features/staffing/Staffing'
import CreateStaffing from '@features/staffing/CreateStaffng/CreateStaffing'
import { staffSelectors, staffThunks } from '@/entity/staffingProject'
import { useDispatch, useSelector } from 'react-redux'
import { InfoDevelopment } from '@/components/infoDevelopment'
import { staffingThunks } from '@/entity/staffing'
import { getStaffingBranchesList, setOneBranch } from '@/entity/administration/thunks'
import { getFromLocalStorage } from '@/shared'
import { Select } from '@/ui'
import { AdministrationInfoWarning } from '@/features'
import cl from 'classnames'
import { LoadingBlocker } from '@/components/Loader'
import { administrationThunks } from '@/entity/administration'

const TabPanel = styled(TabPanelUnstyled)`
	width: 100%;
	max-width: 90vw;
	margin: 32px 34px 0 0;
	font-family: 'Inter', sans-serif;
	font-size: 0.875rem;
`

const TabsList = styled(TabsListUnstyled)`
	width: fit-content;
	max-width: 90vw;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: space-between;
	height: 41px;
`

const StaffingPage = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [activeTab, setActiveTab] = React.useState(0)

	const { orgsList, staffingGrants } = useSelector((state) => ({
		orgsList: state.administration.staffingBranchesList,
		staffingGrants: state.administration.staffingGrants,
	}))

	const activeStaffBranch = getFromLocalStorage('activeStaffBranch')

	useEffect(() => {
		dispatch(staffThunks.getOptions())

		let body = {
			data: ['stRegistration', 'stView'],
			onSuccess: async (item) => {
				if (!activeStaffBranch) {
					dispatch(setOneBranch(item))
				} else {
					dispatch(administrationThunks.getStaffingGrants(activeStaffBranch))
					dispatch(staffingThunks.getActualStaff())
					dispatch(staffThunks.getProject())
				}
			},
		}

		dispatch(getStaffingBranchesList(body))
	}, [])

	const handleChangeTab = (e, newValue) => setActiveTab(newValue)

	const handleChangeOrgs = (value) => {
		if (activeStaffBranch !== value) {
			let item = orgsList.data?.find((item) => item.bin == value)

			dispatch(setOneBranch(item))
		}
	}

	const isVisibleOrgsList = useMemo(() => {
		const res = orgsList?.data?.filter((item) => item.bin !== activeStaffBranch)
		return !!res?.length
	}, [orgsList.data, activeStaffBranch])

	return (
		<>
			<RedirectUnAuthUser>
				<ProfileTemplate title={'staffing'}>
					{isVisibleOrgsList && (
						<>
							<Select
								required
								options={orgsList.data?.map((item) => ({
									code: item.bin,
									kz: item.orgNameKz,
									rus: item.orgNameRu,
								}))}
								value={activeStaffBranch}
								onChange={(value) => handleChangeOrgs(value)}
							/>

							<div className={cl('my-4')}>
								<AdministrationInfoWarning text={t('administration.get_access_warning')} />
							</div>
						</>
					)}

					{staffingGrants.data?.stView && (
						<TabsUnstyled defaultValue={0} value={activeTab} onChange={handleChangeTab}>
							<TabsList>
								<Tab value={0}>{t('staffing')}</Tab>
								<Tab value={1}>{t('staffing_table.project')}</Tab>
								<Tab value={2}>{t('staffing_table.history')}</Tab>
							</TabsList>
							<TabPanel value={0}>
								<Staffing />
							</TabPanel>
							<TabPanel value={1}>
								<CreateStaffing />
							</TabPanel>
							<TabPanel value={2}>
								{/*<History />*/}
								<InfoDevelopment title={'payment.sectionUnderDevelopment'} />
							</TabPanel>
						</TabsUnstyled>
					)}
				</ProfileTemplate>
			</RedirectUnAuthUser>

			{(orgsList.loading || staffingGrants.loading) && <LoadingBlocker />}
		</>
	)
}

export default StaffingPage

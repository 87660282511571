export const formFieldNames = {
	bin: 'bin',
	enterpriseName: 'enterpriseName',
	enterpriseNameKaz: 'enterpriseNameKaz',
	unionBin: 'unionBin',
	unionName: 'unionName',
	unionNameKaz: 'unionNameKaz',
	representativeIin: 'representativeIin',
	representativeFIO: 'representativeFIO',
	collectiveContractNumber: 'collectiveContractNumber',
	dateFrom: 'dateFrom',
	dateTo: 'dateTo',
	overtimePayIs: 'overtimePayIs',
	overtimePay: 'overtimePay',
	holidayPayIs: 'holidayPayIs',
	holidayPay: 'holidayPay',
	nightTimePayIs: 'nightTimePayIs',
	nightTimePay: 'nightTimePay',
	combinationPositionPayIs: 'combinationPositionPayIs',
	combinationPositionPay: 'combinationPositionPay',
	termCompensationPayIs: 'termCompensationPayIs',
	termCompensationPay: 'termCompensationPay',
	wayCompensationPayIs: 'wayCompensationPayIs',
	wayCompensationPay: 'wayCompensationPay',
	wageIndexProcedureIs: 'wageIndexProcedureIs',
	wageIndexProcedure: 'wageIndexProcedure',
	firstCategoryRate: 'firstCategoryRate',
	industryAgreementCoefficient: 'industryAgreementCoefficient',
	minCoefficient: 'minCoefficient',
	maxCoefficient: 'maxCoefficient',
	firstGradeMin: 'firstGradeMin',
	firstGradeMax: 'firstGradeMax',
	secondGradeMin: 'secondGradeMin',
	secondGradeMax: 'secondGradeMax',
	thirdGradeMin: 'thirdGradeMin',
	thirdGradeMax: 'thirdGradeMax',
	fourthGradeMin: 'fourthGradeMin',
	fourthGradeMax: 'fourthGradeMax',
	fifthGradeMin: 'fifthGradeMin',
	fifthGradeMax: 'fifthGradeMax',
	sixthGradeMin: 'sixthGradeMin',
	sixthGradeMax: 'sixthGradeMax',
	seventhGradeMin: 'seventhGradeMin',
	seventhGradeMax: 'seventhGradeMax',
	eighthGradeMin: 'eighthGradeMin',
	eighthGradeMax: 'eighthGradeMax',
	workTime: 'workTime',
	relaxTime: 'relaxTime',
	securityFundingVolume: 'securityFundingVolume',
	fundingAmount: 'fundingAmount',
	dcolContractEnterpriseTypeCode: 'dcolContractEnterpriseTypeCode',
	dcolContractOwnershipTypeCode: 'dcolContractOwnershipTypeCode',

	industryCodes : 'industryCodes',

	positions: 'positions',
	gradeList: 'gradeList',
	gradeCoeff: 'gradeCoeff',
	gradeLevel: 'gradeLevel',
	salarySupplement: 'salarySupplement',
	tariffRate: 'tariffRate',
	positionCode: 'positionCode',
	workConditionsCode: 'workConditionsCode',
	dcontractDateCode: 'dcontractDateCode',
}

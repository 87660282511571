import { useState, useEffect } from 'react'
import { debounce } from 'lodash'

export const BREAKPOINTS = {
	XS: 'xs', 						//<576px
	SM: 'sm', 					 	//≥576px
	MD: 'md',					 		//≥768px
	LG: 'lg', 						//≥992px
	XL: 'xl', 						//≥1200px
	XXL: 'xxl',						//≥1400px
}

let initialState = {
	[BREAKPOINTS.XS]: false,
	[BREAKPOINTS.SM]: false,
	[BREAKPOINTS.MD]: false,
	[BREAKPOINTS.LG]: false,
	[BREAKPOINTS.XL]: false,
	[BREAKPOINTS.XXL]: false,
}

const getBreakpoint = (width = 0) => {
	if(width < 576){
		return {
			[BREAKPOINTS.XS]: true,
			[BREAKPOINTS.SM]: false,
			[BREAKPOINTS.MD]: false,
			[BREAKPOINTS.LG]: false,
			[BREAKPOINTS.XL]: false,
			[BREAKPOINTS.XXL]: false,
		}
	}

	if(width >= 576 && width < 768){
		return {
			[BREAKPOINTS.XS]: true,
			[BREAKPOINTS.SM]: true,
			[BREAKPOINTS.MD]: false,
			[BREAKPOINTS.LG]: false,
			[BREAKPOINTS.XL]: false,
			[BREAKPOINTS.XXL]: false,
		}
	}

	if(width >= 768 && width < 992){
		return {
			[BREAKPOINTS.XS]: true,
			[BREAKPOINTS.SM]: true,
			[BREAKPOINTS.MD]: true,
			[BREAKPOINTS.LG]: false,
			[BREAKPOINTS.XL]: false,
			[BREAKPOINTS.XXL]: false,
		}
	}

	if(width >= 992 && width < 1200){
		return {
			[BREAKPOINTS.XS]: true,
			[BREAKPOINTS.SM]: true,
			[BREAKPOINTS.MD]: true,
			[BREAKPOINTS.LG]: true,
			[BREAKPOINTS.XL]: false,
			[BREAKPOINTS.XXL]: false,
		}
	}

	if(width >= 1200 && width < 1400){
		return {
			[BREAKPOINTS.XS]: true,
			[BREAKPOINTS.SM]: true,
			[BREAKPOINTS.MD]: true,
			[BREAKPOINTS.LG]: true,
			[BREAKPOINTS.XL]: true,
			[BREAKPOINTS.XXL]: false,
		}
	}

	if(width >= 1400){
		return {
			[BREAKPOINTS.XS]: true,
			[BREAKPOINTS.SM]: true,
			[BREAKPOINTS.MD]: true,
			[BREAKPOINTS.LG]: true,
			[BREAKPOINTS.XL]: true,
			[BREAKPOINTS.XXL]: true,
		}
	}

	return initialState
}

/**
 * Хук который возвращает ширину и высоту страницы. А так же возвращает breakpoints.
 * @description
 * Возвращает: {{width: string, height: string}, breakpoint: {}}
 *
 * @returns {{width: string, height: string}, breakpoint: string}
 */

export const useWindowSize = () => {
	const [size, setSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})

	const [breakpoint, setBreakpoint] = useState(getBreakpoint(window.innerWidth))

	useEffect(() => {
		const handleResize = debounce(() => {
			let width = window.innerWidth
			let height = window.innerHeight

			setBreakpoint(getBreakpoint(width))
			setSize({ width: width, height: height })
		}, 100)

		window.addEventListener('resize', handleResize)

		return () => {
			handleResize.cancel()
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return {size, breakpoint}
}
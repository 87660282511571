import cl from 'classnames'
import styles from './Style.module.scss'
import { useTranslation } from 'react-i18next'

const Hero = () => {
	const { t } = useTranslation()

	const array = [
		{
			title: '5,5 млн',
			subtitle: t('contracts')
		},
		{
			title: '407 496',
			subtitle: t('count_employers')
		},
		{
			title: '4,9 млн',
			subtitle: t('count_workers')
		}
	]

	return (
		<section data-section="hero" className={cl(styles.hero)}>
			<div className={cl(styles.container, styles.hero__wrapper)}>
				<h1 className={cl(styles.hero__title, 'mb-0')}>{t('intro_sub_title')}</h1>

				<div className={cl(styles.hero__card)}>
					{array.map((item, index) => (
						<div className={cl(styles.hero__cardItem)} key={index}>
							<h2 className={'mb-0'}>{item.title}</h2>
							<p className={'mb-0'}>{item.subtitle}</p>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

export default Hero
import React, { memo } from 'react'
import styles from './Input.module.scss'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'

export const Input = memo(({
														 prefix,
														 postfix,
														 placeholder,
														 value,
														 onChange,
													 }) => {
	return (
		<div className={styles.search__field}>
			{
				prefix &&
				<IconButton type="submit" aria-label="prefix" className={styles.search__icon}>
					{prefix}
				</IconButton>
			}
			<InputBase
				fullWidth
				placeholder={placeholder}
				inputProps={{ 'aria-label': 'search' }}
				value={value}
				onChange={onChange}
			/>
			{
				postfix &&
				<IconButton type="submit" aria-label="postfix" className={styles.search__icon}>
					{postfix}
				</IconButton>
			}

		</div>
	)
})
import React, { useCallback, useEffect, useState } from 'react'
import { Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import styles from './StaffingPositionForm.module.scss'
import { Button, Field, Title } from '@/ui'
import { formFieldNames } from './formFieldNames'
import { useTranslation } from 'react-i18next'
import { WrappedCheckbox } from '@features/contracts/registrationEmployerContract/form/fields'
import { getFromLocalStorage, useToggle } from '@/shared'
import { request } from '@/shared/api/request'
import { message } from 'antd'
import { useSelector } from 'react-redux'
import { staffingSelectors } from '@/entity'
import { formatNumber } from '@/utils'
import { getCurrentLanguage } from '@/i18next'
import { contractActionsSelectors } from '@features/contracts/model'
import { checkEquality } from '@/utils/checkEquality'

export const Form = ({ actions, onClose, workPlaceRowSelect }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const { values, setFieldValue } = useFormikContext()
	const { positions, positionsByKnz } = useSelector(contractActionsSelectors.options)
	const [isPositionFilled, togglePositionFilled] = useToggle(false)
	const [disabled, setDisabled] = useState(false)
	const [disabledWP, setDisabledWP] = useState(false)
	const [isCombining, setIsCombining] = useState(false)
	const [dicContracts, setDicContracts] = useState([])
	const [contractList, setContractList] = useState([])
	const [emptyContract, setEmptyContract] = useState(false)
	const [positionCodeError, setPositionCodeError] = useState(false)
	const [establishedPostError, setEstablishedPostError] = useState(false)
	const [inputValues, setInputValues] = useState({
		factSalary: '',
		salarySupplement: '',
	})
	const isEdit = actions.edit
	const activeStaffBranch = getFromLocalStorage('activeStaffBranch')

	useEffect(() => {
		if (isEdit) {
			setDisabled(true)
			setTimeout(() => setDisabled(false), 10000)
			getFlData(values[formFieldNames.iin]).then((value) => {
				if (value.fio !== null) setFieldValue(formFieldNames.employeeInitials, value.fio || '')
				else message.error(t('staff.error_no_contract'), 5)
			})
		}
	}, [isEdit])

	useEffect(() => {
		if (isEdit && values[formFieldNames.substitutionExternalId]?.length > 0) {
			const value = getSubstitutionForId(values[formFieldNames.substitutionExternalId])
			setFieldValue(formFieldNames.substitutionIin, value.substitutionIin || '')
			setFieldValue(formFieldNames.substitutionName, value.substitutionName || '')
			setFieldValue('substitutionExternalId', value.externalId || '')
		}
	}, [values[formFieldNames.substitutionExternalId]?.length, isEdit])

	useEffect(() => {
		const valueLength = values[formFieldNames.substitutionIin]?.length

		if (isEdit && valueLength === 12) {
			togglePositionFilled(true)
			setFieldValue('substitutionExternalId', values.substitutionExternalId || '')
			setFieldValue('isReplacement', true)
		} else {
			setFieldValue('substitutionExternalId', '')
		}
	}, [values[formFieldNames.substitutionIin]?.length, isEdit])

	useEffect(() => {
		if (values.contracts) {
			const selectedContract = contractList.find((contract) => contract.externalId === values.contracts)
			setFieldValue('externalId', selectedContract.externalId)
			setFieldValue('establishedPost', selectedContract.establishedPost)
			setFieldValue('position', selectedContract.establishedPost.code)
			setFieldValue('positionCode', selectedContract.positionCode)
			if (selectedContract && !isCombining) {
				const errors = checkEquality(selectedContract, workPlaceRowSelect)
				setPositionCodeError(errors.positionCodeError)
				setEstablishedPostError(errors.establishedPostError)
			} else if (selectedContract && isCombining) {
				setPositionCodeError(false)
				setEstablishedPostError(false)
			}
		}
	}, [values.contracts, isCombining])

	useEffect(() => {
		if (dicContracts.length === 1) {
			setFieldValue('contracts', dicContracts[0].code)
		}
	}, [dicContracts])

	const handleInputChange = (fieldName) => (event) => {
		let value = event.target.value
		if (value && !inputValues[fieldName]) {
			// Значение существует и ранее не было установлено, устанавливаем его напрямую
			setInputValues({ ...inputValues, [fieldName]: value })
		} else {
			// В противном случае очищаем и форматируем
			const onlyNumbers = value.replace(/\D/g, '')
			const numberWithSpaces = formatNumber(onlyNumbers)
			setInputValues({ ...inputValues, [fieldName]: numberWithSpaces })
			setFieldValue(fieldName, onlyNumbers)
		}
	}

	const wplaceEmployeeList = useSelector(staffingSelectors.wplaceEmployeeList)

	const handleCheckboxChange = (value) => {
		togglePositionFilled(value)
		setFieldValue('isReplacement', !isPositionFilled)
		if (!value) {
			setFieldValue('substitutionExternalId', '')
			setFieldValue('substitutionIin', '')
			setFieldValue('substitutionName', '')
		}
	}

	const handleCombinationCheckboxChange = (value) => {
		setIsCombining(value)
		setFieldValue('isCombining', value)
	}

	// Получаем данные с помощью запроса
	const getFlData = useCallback(
		async (iin) => {
			try {
				let params = {
					iin: iin,
				}
				const response = await request({
					url: '/staff/universalMethods/actualContractData',
					method: 'GET',
					params: params,
				})
				// Обрабатываем полученные данные
				const contracts = response.contractDatas
				let dicContracts = []

				if (contracts) {
					setEmptyContract(false)
					setContractList(response.contractDatas)
					dicContracts = contracts.map((contract) => {
						const infoString = t('col_contract.contract_short_information', {
							contractNumber: contract.contractNumber,
							dateFrom: contract.beginDate,
						})

						return {
							code: contract.externalId,
							kz: infoString,
							rus: infoString,
						}
					})
				} else {
					setEmptyContract(true)
					setContractList([])
					setDicContracts([])
					setFieldValue('contracts', null)
					setFieldValue('fio', null)
					setFieldValue('position', null)
					setFieldValue('positionCode', null)
					setFieldValue('establishedPost', null)
				}

				setDicContracts(dicContracts)
				return response
			} catch (error) {
				throw error
			}
		},
		[lang, activeStaffBranch]
	)

	const handleClick = () => {
		setDisabled(true)
		setTimeout(() => setDisabled(false), 10000)
		getFlData(values[formFieldNames.iin]).then((value) => {
			if (value.fio !== null) setFieldValue(formFieldNames.employeeInitials, value.fio || '')
			else message.error(t('staff.error_no_contract'), 5)
		})
	}

	const handleWPClick = () => {
		setDisabledWP(true)
		setTimeout(() => setDisabledWP(false), 10000)
		const value = getSubstitution(values[formFieldNames.substitutionIin])
		if (value.substitutionName !== null) {
			setFieldValue(formFieldNames.substitutionName, value.substitutionName || '')
			setFieldValue('substitutionExternalId', value.externalId || '')
		} else {
			message.error(t('person_not_found'), 5)
		}
	}

	const getSubstitution = (inputIin) => {
		const substitution = wplaceEmployeeList.find((item) => item.iin === inputIin)
		return {
			substitutionName: substitution ? substitution.fullName : null,
			externalId: substitution ? substitution.externalId : null,
		}
	}

	const getSubstitutionForId = (substitutionExternalId) => {
		const substitution = wplaceEmployeeList.find((item) => item.externalId === substitutionExternalId)
		return {
			substitutionName: substitution ? substitution.fullName : null,
			substitutionIin: substitution ? substitution.iin : null,
			externalId: substitution ? substitution.externalId : null,
		}
	}

	return (
		<FormikForm className={styles.form}>
			<section>
				<div className={styles.item}>
					<div className={'flex align-items-baseline'}>
						<Title>{t('employee_data')}</Title>
						<div className={styles.mr20}></div>
						<WrappedCheckbox onChange={handleCheckboxChange} value={isPositionFilled} label={t('position_filled')} />
						<div className={styles.mr20}></div>
						<WrappedCheckbox
							onChange={handleCombinationCheckboxChange}
							value={isCombining}
							label={t('positions_combination')}
						/>
					</div>
					{isPositionFilled ? (
						<div className={styles.fields}>
							<FormikField name={formFieldNames.substitutionIin}>
								{({ field, meta: { touched, error } }) => (
									<Field
										{...field}
										type="number"
										hideNumberArrows
										isRequired
										label={t('staff.substitute_yin')}
										placeholder={t('enter_count_digitals', { count: 12 })}
										error={touched && error}
										maxLength={12}
									/>
								)}
							</FormikField>
							<div className={styles.fieldAndButton}>
								<Button type="button" disabled={disabledWP} onClick={handleWPClick}>
									{t('find')}
								</Button>
								<FormikField name={formFieldNames.substitutionName}>
									{({ field, meta: { touched, error } }) => (
										<Field
											{...field}
											type="text"
											isRequired
											disabled
											label={t('employee_initials')}
											placeholder={t('filled_automate')}
											error={touched && error}
										/>
									)}
								</FormikField>
							</div>
						</div>
					) : null}
					<div className={styles.fields}>
						<FormikField name={formFieldNames.iin}>
							{({ field, meta: { touched, error } }) => (
								<Field
									{...field}
									type="number"
									hideNumberArrows
									isRequired
									label={t('employee_yin')}
									placeholder={t('enter_count_digitals', { count: 12 })}
									error={touched && error}
									maxLength={12}
								/>
							)}
						</FormikField>
						<div className={styles.fieldAndButton}>
							<Button type="button" disabled={disabled} onClick={handleClick}>
								{t('find')}
							</Button>
							<FormikField name={formFieldNames.employeeInitials}>
								{({ field, meta: { touched, error } }) => (
									<Field
										{...field}
										type="text"
										isRequired
										disabled
										label={t('employee_initials')}
										placeholder={t('filled_automate')}
										error={touched && error}
									/>
								)}
							</FormikField>
						</div>
						{emptyContract ? <p className={styles.error}>{t('staff.error_empty_contract')}</p> : null}
					</div>
				</div>
			</section>
			<section>
				<div className={styles.item}>
					<div className={`${styles.fields} ${styles.fields_verticalCenter}`}>
						<Title>{t('contracts_tab.information_about_contract')}</Title>
					</div>
					{!isCombining ? (
						<>
							<FormikField name="contracts">
								{({ field, meta: { touched, error } }) => (
									<Field
										{...field}
										fieldType="selectSearch"
										label={t('employment_contract_1')}
										placeholder={t('choose_employment_contract')}
										options={dicContracts}
										error={touched && error}
										isRequired
									/>
								)}
							</FormikField>
							<FormikField name="position">
								{({ field, meta: { touched, error } }) => (
									<>
										<Field
											{...field}
											fieldType="selectSearch"
											label={t('contract.info.position')}
											placeholder={t('filled_automate')}
											options={positions}
											error={touched && error}
											isRequired
											disabled
										/>
										{establishedPostError && <p className={styles.error}>{t('staff.error_position')}</p>}
									</>
								)}
							</FormikField>
							<FormikField name="positionCode">
								{({ field, meta: { touched, error } }) => (
									<>
										<Field
											{...field}
											fieldType="selectSearch"
											label={t('contract.info.nkz_position')}
											placeholder={t('filled_automate')}
											options={positionsByKnz}
											error={touched && error}
											isRequired
											disabled
										/>
										{positionCodeError && <p className={styles.error}>{t('staff.error_position_nkz')}</p>}
									</>
								)}
							</FormikField>
						</>
					) : null}
					<div className={`${styles.fields} ${styles.threeFields}`}>
						<FormikField name={formFieldNames.tariffRate}>
							{({ field, meta: { touched, error } }) => (
								<Field
									{...field}
									type="number"
									hideNumberArrows
									isRequired
									label={t('contracts_tab.tarrif_rate')}
									placeholder={t('staff_modal.number_bets_placeholder')}
									error={touched && error}
									enablecheckfloat
								/>
							)}
						</FormikField>
						<FormikField name={formFieldNames.factSalary}>
							{({ field, meta: { touched, error } }) => (
								<Field
									isRequired
									label={t('staffing_table.sum')}
									placeholder={t('staff.actual_salary_placeholder')}
									error={touched && error}
									type="text"
									fieldType={'inputNumber'}
									value={inputValues.factSalary || field.value} // Используем значение из состояния если оно существует
									onChange={handleInputChange(formFieldNames.factSalary)}
									autoComplete="off"
									{...field}
								/>
							)}
						</FormikField>
						<FormikField name={formFieldNames.salarySupplement}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									fieldType={'inputNumber'}
									hideNumberArrows
									label={t('staffing_table.supplement_surcharge')}
									placeholder={t('staff_modal.supplement_surcharge_placeholder')}
									error={touched && error}
									value={inputValues.salarySupplement || field.value} // Используем значение из состояния если оно существует
									onChange={handleInputChange(formFieldNames.salarySupplement)}
									autoComplete="off"
									{...field}
								/>
							)}
						</FormikField>
					</div>
					<div className={`${styles.fields} ${styles.threeFields}`}>
						<FormikField name={formFieldNames.startContractDate}>
							{({ field, meta: { touched, error } }) => (
								<Field
									{...field}
									fieldType="datePicker"
									label={t('payment.table.appointmentDate')}
									isRequired
									placeholder={t('default_date_value')}
									error={touched && error}
								/>
							)}
						</FormikField>
						<FormikField name={formFieldNames.endContractDate}>
							{({ field, meta: { touched, error } }) => (
								<Field
									{...field}
									fieldType="datePicker"
									label={t('contracts_tab.end_contract_date')}
									placeholder={t('default_date_value')}
									error={touched && error}
								/>
							)}
						</FormikField>
					</div>
					<FormikField name={formFieldNames.cause}>
						{({ field, meta: { touched, error } }) => (
							<Field
								{...field}
								fieldType={'textarea'}
								label={t('staffing_table.reason_temporary')}
								placeholder={t('staff_modal.reason_temporary_placeholder')}
								error={touched && error ? t(error) : ''}
								showCount
								maxLength={4000}
								rows={3}
							/>
						)}
					</FormikField>
				</div>
			</section>
			<div className={styles.actions}>
				<Button type="bare" onClick={onClose}>
					{t('cancel')}
				</Button>
				<Button
					textColor="green"
					borderColor="green"
					transparent
					border
					type="submit"
					disabled={positionCodeError || establishedPostError}
				>
					{t('save')}
				</Button>
			</div>
		</FormikForm>
	)
}

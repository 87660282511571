import styled from 'styled-components'
import Slider from 'react-slick'

export const StyledSlider = styled(Slider)`
    .slick-list {
        width: 100% !important;
        margin-left: 0 !important;
				z-index: 100;
    }

    .slick-prev {
        left: -5% !important;
				z-index: 1000;

        @media (max-width: 768px) {
            left: -2% !important;
        }

        @media (min-width: 992px){
            left: -2% !important;
        }
    }

    .slick-next {
        right: -5% !important;
				z-index: 1000;

        @media (max-width: 768px) {
            right: -2% !important;
        }
				
        @media (min-width: 992px) {
            right: -2% !important;
        }
    }
`
import * as types from './types'

const data = {
	loading: false,
	success: false,
	failed: false,
	data: null,
}

const initialState = {
	regionalPowerContact: null,

	feedback: data
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.SET_REGIONAL_POWER_CONTACT:
			return {
				...state,
				regionalPowerContact: payload,
			}


		case types.SET_FEEDBACK_ACTION:
			return {
				...state,
				feedback: {...data, loading: true},
			}

		case types.SET_FEEDBACK_ACTION_DONE:
			return {
				...state,
				feedback: {...data, success: true, data: payload},
			}

		case types.SET_FEEDBACK_ACTION_FAIL:
			return {
				...state,
				feedback: {...data, failed: true},
			}
		default:
			return state
	}
}

import { useTranslation } from 'react-i18next'
import { connect, ErrorMessage, Field as FormikField, FieldArray, Form as FormikForm, useFormikContext } from 'formik'
import { Button, Field, Title } from '@/ui'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { formFieldNames } from '@/entity/collectiveContracts'
import styles from './CollectiveForm.module.scss'
import { userThunks } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { PayFields, WorkingHolidaysFields } from './fields'
import { ConfirmDocument } from '@/components/AddDocuments/ConfirmDocument'
import { getCurrentLanguage } from '@/i18next'
import { Add, Trash } from '@app/icons'
import { api } from '@/shared'
import { GuaranteesSection } from '@/components/GuaranteesSection/GuaranteesSection'
import cl from 'classnames'
import { TariffRatesFields } from '@/components/TariffRatesFields/TariffRatesFields'
import { UPLOAD_TYPE, Uploader } from '@/components/Uploader/Uploader'
import {BACKEND_FILE_TYPE, FILE_FORMAT} from '@app/config'

export const UnionNameField = connect(({ fieldName, disabled = false, handleChange, unionIndex }) => {
	const { t } = useTranslation()
	return (
		<FormikField name={fieldName}>
			{({ field, meta: { touched, error } }) => (
				<Field
					{...field}
					type="text"
					wrapperClassName={styles.field__input}
					label={t('col_contract.union_name')}
					placeholder={t('filled_automate')}
					error={touched && error ? t(error) : ''}
					isRequired
					onChange={(event) => handleChange(event, unionIndex)}
					disabled={disabled}
				/>
			)}
		</FormikField>
	)
})

let UPLOAD_FORM_KEY = {
	fileProtocolDisagreement: 'fileProtocolDisagreement',
	files: 'files',
}

let errorInitialState = {
	[UPLOAD_FORM_KEY.files]: false,
	[UPLOAD_FORM_KEY.fileProtocolDisagreement]: false,
}

export const CollectiveForm = ({ options = {}, files, onCancel }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const { values, setFieldValue } = useFormikContext()
	const dispatch = useDispatch()

	const { activity, industryAgreement, dcontractDateCode } = useSelector((state) => ({
		activity: state.handbook.singleOptions,
		industryAgreement: state.handbook.industryAgreement,
		dcontractDateCode: state.handbook.contractTerms,
	}))

	const {
		workTimeCollectiveContract,
		relaxTimeCollectiveContract,
		unionTypeCollectiveContract,
		extraGuaranteesCollectiveContract,
		ccEnterpriseType,
		ccOwnershipType,
	} = options
	const [isDisableError, setIsDisableError] = useState(errorInitialState)

	useEffect(() => {
		setFieldValue('union', '1')
	}, [])

	const onSuccessDocument = (key, value) => {
		let res = (value || []).map(item => item)

		setFieldValue(key, res)
	}

	const onErrorDocument = (key, value) => {
		setIsDisableError(prevState => ({
			...prevState,
			[key]: value
		}))
	}

	const isDisableSubmit = useMemo(() => {
		return !isDisableError[UPLOAD_FORM_KEY.files] && !isDisableError[UPLOAD_FORM_KEY.fileProtocolDisagreement]
	}, [isDisableError])


	useEffect(() => {
		const { oked } = activity
		if (!oked) return

		const { fullNameRu, fullNameKz } = oked
		if (!fullNameRu && !fullNameKz) {
			return
		}

		setFieldValue('enterpriseName', fullNameRu)
		setFieldValue('enterpriseNameKaz', fullNameKz)
		dispatch(userThunks.removeOked())
	}, [activity.oked])

	useEffect(() => {
		if (activity.okedUnion) {
			setFieldValue('unionName', activity.okedUnion.fullNameRu)
			setFieldValue('unionNameKaz', activity.okedUnion.fullNameKz)
			dispatch(userThunks.removeOkedUnion())
		}
	}, [activity.okedUnion])

	useEffect(() => {
		if (activity.okedUnionIin) {
			setFieldValue('representativeFIO', activity.okedUnionIin.fullNamePerson)
			dispatch(userThunks.removeOkedUnionIin())
		}
	}, [activity.okedUnionIin])

	const handleSearchBin = async () => {
		if (values.bin.length < 12) {
			return
		}
		const regexp = /^[0-9]{12}$/
		if (regexp.test(values.bin)) {
			onGetHandBookOptions(values.bin.charAt(4) >= 4 ? 'bin' : 'iin', values.bin)
		}
	}

	const onGetHandBookOptions = useCallback(
		(type, value) => {
			dispatch(userThunks.getEmployerActivityByIINAndBin({ code: value }))
		},
		[dispatch]
	)
	const handleSearchUnionBin = async (bin, unionIndex) => {
		if (bin.length < 12) {
			return
		}
		const { fullNameKz, fullNameRu } = await api.profile.getEmployerActivityByBIN({ bin: bin })
		setFieldValue(`unions[${unionIndex}].unionName`, fullNameRu)
		setFieldValue(`unions[${unionIndex}].unionNameKaz`, fullNameKz)
	}
	const handleSearchUnionIin = async (iin, unionIndex, repIndex) => {
		if (iin.length < 12) {
			return
		}
		const { fullNamePerson } = await api.profile.getEmployerActivityByIIN({ iin: iin })
		setFieldValue(`unions[${unionIndex}].representativeDtos[${repIndex}].representativeFIO`, fullNamePerson)
	}
	const handleChange = (event, unionIndex) => {
		setFieldValue(`unions.${unionIndex}.unionNameKaz`, event)
		setFieldValue(`unions.${unionIndex}.unionName`, event)
	}

	const addUnion = () => {
		setFieldValue('unions', [
			...values.unions,
			{
				representativeDtos: [
					{
						representativeFIO: '',
						representativeIin: '',
					},
				],
				unionBin: '',
				unionName: '',
				unionNameKaz: '',
				unionTypeCode: '',
			},
		])
	}

	const addRepresentative = (unionIndex) => {
		setFieldValue(`unions[${unionIndex}].representativeDtos`, [
			...values.unions[unionIndex].representativeDtos,
			{
				representativeFIO: '',
				representativeIin: '',
			},
		])
	}
	const addGuarantee = () => {
		setFieldValue(`extraGuarantee`, [
			...values.extraGuarantee,
			{
				code: '',
				value: '',
			},
		])
	}

	const removeUnion = (index) => {
		const updatedUnions = [...values.unions]
		updatedUnions.splice(index, 1)
		setFieldValue('unions', updatedUnions)
	}

	const removeGuarantee = (index) => {
		const updatedGuarantee = [...values.extraGuarantee]
		updatedGuarantee.splice(index, 1)
		setFieldValue('extraGuarantee', updatedGuarantee)
	}

	const getGuaranteeLabel = (id) => {
		const guarantee = extraGuaranteesCollectiveContract.find((item) => item.code === id.toString())
		return guarantee[lang]
	}
	return (
		<FormikForm className={styles.form}>
			<section>
				<Title>{t('employer_data')}</Title>
				<div className={styles.fields__wrapper}>
					<div className={styles.fieldAndButton}>
						<FormikField name={formFieldNames.bin}>
							{({ field, meta: { touched, error } }) => (
								<Field
									wrapperClassName={styles.field__input}
									hideNumberArrows
									label={t('col_contract.bin')}
									placeholder={t('enter_count_digitals', { count: 12 })}
									{...field}
									error={touched && error ? t(error) : ''}
									isRequired
									maxLength={12}
									pattern="[0-9]{12}"
									onInput={(e) => {
										e.target.value = e.target.value.replace(/[^0-9]/g, '')
									}}
								/>
							)}
						</FormikField>
						<Button disabled={!values[formFieldNames.bin]} onClick={handleSearchBin.bind(null)}>
							{t('find')}
						</Button>
					</div>
					<div className={styles.fields__item__no__button}>
						<FormikField name={formFieldNames.enterpriseName}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									disabled
									wrapperClassName={styles.field__input}
									label={t('col_contract.name')}
									placeholder={t('filled_automate')}
									{...field}
									error={touched && error ? t(error) : ''}
									isRequired
								/>
							)}
						</FormikField>
					</div>
				</div>
				<div className={`${styles.fields} ${styles.twoFields} mt-4`}>
					<FormikField name={formFieldNames.dcolContractEnterpriseTypeCode}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'select'}
								isRequired
								label={t('col_contract.typeEnterprise')}
								placeholder={t('select_from_list')}
								options={ccEnterpriseType}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
					<FormikField name={formFieldNames.dcolContractOwnershipTypeCode}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'select'}
								isRequired
								label={t('col_contract.typeOwnership')}
								placeholder={t('select_from_list')}
								options={ccOwnershipType}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
				</div>
				<div className={cl('mt-4')}>
					<FormikField name={formFieldNames.industryCodes}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'multipleSelect'}
								isRequired
								label={t('col_contract.industry')}
								placeholder={t('select_from_list')}
								options={industryAgreement}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
				</div>
			</section>
			<section>
				<Title>{t('union_data')}</Title>
				{values.unions.map((union, unionIndex) => (
					<React.Fragment key={unionIndex}>
						<p className={styles.subTitle}>
							{t('col_contract.union')} {unionIndex + 1}
						</p>
						<div className={unionIndex ? styles.fieldAndRemoveButton : null}>
							<FormikField name={`unions.${unionIndex}.unionTypeCode`}>
								{({ field, meta: { touched, error } }) => (
									<Field
										fieldType={'select'}
										isRequired
										label={t('col_contract.col_union')}
										placeholder={t('choose_from_directory')}
										options={unionTypeCollectiveContract}
										error={touched && t(error)}
										{...field}
									/>
								)}
							</FormikField>
							{unionIndex > 0 && (
								<Button
									transparent
									onClick={() => removeUnion(unionIndex)}
									buttonClassName={styles.buttonRed}
									disabled={false}
								>
									<Trash className={styles.iconRed} />
									{t('remove')}
								</Button>
							)}
						</div>
						{union?.unionTypeCode === '2' ? (
							<div className={styles.fields__iin__wrapper}>
								<div className={styles.fieldAndButton}>
									<FormikField name={`unions.${unionIndex}.unionBin`}>
										{({ field, meta: { touched, error } }) => (
											<Field
												{...field}
												placeholder={t('enter_count_digitals', { count: 12 })}
												error={touched && error ? t(error) : ''}
												wrapperClassName={styles.field__input}
												label={t('col_contract.union_bin')}
												maxLength={12}
												pattern="[0-9]{12}"
												onInput={(e) => {
													e.target.value = e.target.value.replace(/[^0-9]/g, '')
												}}
											/>
										)}
									</FormikField>
									<Button disabled={!union?.unionBin} onClick={() => handleSearchUnionBin(union.unionBin, unionIndex)}>
										{t('find')}
									</Button>
								</div>
								{lang === 'rus' ? (
									<div className={styles.fields__item__no__button}>
										<UnionNameField
											unionIndex={unionIndex}
											handleChange={handleChange}
											fieldName={`unions.${unionIndex}.unionName`}
											disabled={true}
										/>
									</div>
								) : (
									<div className={styles.fields__item__no__button}>
										<UnionNameField
											unionIndex={unionIndex}
											handleChange={handleChange}
											fieldName={`unions.${unionIndex}.unionNameKaz`}
											disabled={true}
										/>
									</div>
								)}
							</div>
						) : (
							union?.unionTypeCode === '1' &&
							(lang === 'rus' ? (
								<div className={styles.fields_union_name}>
									<UnionNameField
										unionIndex={unionIndex}
										handleChange={handleChange}
										fieldName={`unions.${unionIndex}.unionName`}
									/>
								</div>
							) : (
								<div className={styles.fields_union_name}>
									<UnionNameField
										unionIndex={unionIndex}
										handleChange={handleChange}
										fieldName={`unions.${unionIndex}.unionNameKaz`}
									/>
								</div>
							))
						)}
						{union.representativeDtos.map((representative, repIndex) => (
							<div key={repIndex} className={styles.fields__iin__wrapper}>
								<div className={styles.fieldAndButton}>
									<FormikField name={`unions.${unionIndex}.representativeDtos.${repIndex}.representativeIin`}>
										{({ field, meta: { touched, error } }) => (
											<Field
												{...field}
												placeholder={t('enter_count_digitals', { count: 12 })}
												error={touched && error ? t(error) : ''}
												wrapperClassName={styles.field__input}
												label={t('col_contract.representativeIin')}
												maxLength={12}
												pattern="[0-9]{12}"
												onInput={(e) => {
													e.target.value = e.target.value.replace(/[^0-9]/g, '')
												}}
											/>
										)}
									</FormikField>
									<Button
										disabled={!representative.representativeIin}
										onClick={() => handleSearchUnionIin(representative.representativeIin, unionIndex, repIndex)}
									>
										{t('find')}
									</Button>
								</div>
								<div className={repIndex ? styles.fieldAndRemoveButtonIIN : styles.fields__item__no__button}>
									<FormikField name={`unions.${unionIndex}.representativeDtos.${repIndex}.representativeFIO`}>
										{({ field, meta: { touched, error } }) => (
											<Field
												type="text"
												wrapperClassName={styles.field__input}
												label={t('col_contract.representativeFIO')}
												placeholder={t('filled_automate')}
												{...field}
												error={touched && error ? t(error) : ''}
												isRequired
											/>
										)}
									</FormikField>
									{repIndex > 0 && (
										<Button
											transparent
											onClick={() => removeUnion(unionIndex)}
											buttonClassName={styles.buttonRed}
											disabled={false}
										>
											<Trash className={styles.iconRed} />
											{t('remove')}
										</Button>
									)}
								</div>
							</div>
						))}
						{union?.unionTypeCode === '3' && (
							<Button transparent onClick={() => addRepresentative(unionIndex)} buttonClassName={styles.button}>
								<Add className={styles.iconGreen} />
								{t('col_contract.add_representative')}
							</Button>
						)}
					</React.Fragment>
				))}
				<Button transparent onClick={addUnion} buttonClassName={styles.button}>
					<Add className={styles.iconGreen} />
					{t('col_contract.add_union')}
				</Button>
			</section>
			<section>
				<Title>{t('col_contract.info')}</Title>
				<div className={cl(styles.fields, styles.twoFields, 'mt-4')}>
					<FormikField name={formFieldNames.collectiveContractNumber}>
						{({ field, meta: { touched, error, errors } }) => (
							<>
								<Field
									type="text"
									label={t('col_contract.number')}
									placeholder={t('col_contract.placeholder_number')}
									error={touched && error}
									{...field}
								/>
								{errors?.['collectiveContractNumber'] && (
									<div style={{ color: 'red' }}>{errors?.['collectiveContractNumber']}</div>
								)}
							</>
						)}
					</FormikField>
					<FormikField name={formFieldNames.dcontractDateCode}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'select'}
								isRequired
								label={t('col_contract.date_end_col_contract')}
								placeholder={t('select_from_list')}
								options={(dcontractDateCode || []).filter(item => item.id === 1 || item.id === 10)}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
				</div>
				<div className={cl(styles.fields, styles.twoFields, 'mt-4')}>
					<FormikField name={formFieldNames.dateFrom}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="datePicker"
								label={t('col_contract.dateFrom')}
								isRequired
								placeholder={t('default_date_value')}
								minDate={new Date(1900, 0, 1)}
								error={touched && error}
								{...field}
							/>
						)}
					</FormikField>
					<FormikField name={formFieldNames.dateTo}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="datePicker"
								label={t('col_contract.dateTo')}
								placeholder={t('default_date_value')}
								minDate={new Date(1900, 0, 1)}
								error={touched && error ? t('required') : ''}
								{...field}
							/>
						)}
					</FormikField>
				</div>
			</section>
			<section>
				<Title>{t('col_contract.pay_info')}</Title>
				<PayFields />
			</section>
			<section>
				<Title>{t('col_contract.info_tariff_rates')}</Title>
				<TariffRatesFields />
			</section>
			<section>
				<Title>{t('col_contract.duration_working_hours_and_rest_time_holidays')}</Title>
				<WorkingHolidaysFields
					workTimeCollectiveContract={workTimeCollectiveContract}
					relaxTimeCollectiveContract={relaxTimeCollectiveContract}
				/>
			</section>
			<section>
				<Title>{t('col_contract.safe_working_protection')}</Title>
				<div className={styles.mbFields}>
					<FormikField name={formFieldNames.securityFundingVolume}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType={'textarea'}
								isRequired
								label={t('col_contract.amount_funding')}
								placeholder={t('col_contract.placeholder_amount_text')}
								error={touched && error ? t(error) : ''}
								{...field}
								showCount
								maxLength={4000}
								rows={6}
							/>
						)}
					</FormikField>
				</div>
				<div className={styles.mbFields}>
					<FormikField name={formFieldNames.fundingAmount}>
						{({ field, meta: { touched, error } }) => (
							<Field
								type="number"
								min={0}
								hideNumberArrows
								enablecheckfloat
								label={t('col_contract.funding_amount')}
								placeholder={t('col_contract.amount')}
								error={touched && error ? t(error) : ''}
								{...field}
								wrapperClassName={styles.securityFundingVolume}
							/>
						)}
					</FormikField>
				</div>
			</section>
			<GuaranteesSection
				values={values}
				extraGuaranteesCollectiveContract={extraGuaranteesCollectiveContract}
				removeGuarantee={removeGuarantee}
				addGuarantee={addGuarantee}
				getGuaranteeLabel={getGuaranteeLabel}
				t={t}
				title={t('col_contract.guarantee_title')}
			/>
			<section>
				<Title>{t('col_contract.protocol_disagreements')}</Title>
				<FormikField name={UPLOAD_FORM_KEY.fileProtocolDisagreement}>
					{({field}) => {
						return (
							<Uploader
								backendFileType={BACKEND_FILE_TYPE.COLLECTIVECONTRACT}
								acceptedFileTypes={[FILE_FORMAT.jpg, FILE_FORMAT.jpeg, FILE_FORMAT.png, FILE_FORMAT.pdf]}
								value={Array.isArray(field.value) ? field.value : [field.value]}
								onSuccess={(values) => onSuccessDocument(UPLOAD_FORM_KEY.fileProtocolDisagreement, values)}
								onError={(error) => onErrorDocument(UPLOAD_FORM_KEY.fileProtocolDisagreement, error)}
								maxFiles={1}
							/>
						)
					}}
				</FormikField>
			</section>
			<section>
				<Title>{t('col_contract.scanned_col_contract')}</Title>
				<FormikField name={UPLOAD_FORM_KEY.files}>
					{({field}) => {
						return (
							<Uploader
								backendFileType={BACKEND_FILE_TYPE.COLLECTIVECONTRACT}
								acceptedFileTypes={[FILE_FORMAT.jpg, FILE_FORMAT.jpeg, FILE_FORMAT.png, FILE_FORMAT.pdf]}
								value={field.value}
								onSuccess={(values) => onSuccessDocument(UPLOAD_FORM_KEY.files, values)}
								onError={(error) => onErrorDocument(UPLOAD_FORM_KEY.files, error)}
							/>
						)
					}}
				</FormikField>
			</section>
			<div className={styles.actions}>
				<Button type="bare" onClick={onCancel}>
					{t('cancel')}
				</Button>
				<Button type="submit" disabled={!isDisableSubmit} >
					{t('sign_and_save')}
				</Button>
			</div>
		</FormikForm>
	)
}

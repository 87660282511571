import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'

export const LANGUAGE_ENUM = {
	kz: 'kz',
	rus: 'rus',
}

export const useLanguage = () => {
	const { t, i18n } = useTranslation()
	const currentLanguage = getCurrentLanguage()

	const lang = currentLanguage || LANGUAGE_ENUM.kz

	const handleChangeLanguage = async (value) => {
		await i18n.changeLanguage(value)
		await localStorage.setItem('lang', value)
	}

	return {
		lang,
		handleChangeLanguage
	}
}